.sub-modal-box-enter {
    opacity: 0;
    display: flex !important;
}
.sub-modal-box-enter-active {
    opacity: 1;
    display: flex !important;
    transition: opacity 250ms;
}
.sub-modal-box-enter-done {
    opacity: 1 !important;
    display: flex !important;
}
.sub-modal-box-exit {
    opacity: 1;
    display: flex !important;
}
.sub-modal-box-exit-active {
    opacity: 0;
    transition: opacity 250ms;
    display: flex !important;
}

.subscribe-form-modal {
    width: 546px;
    height: 454px;
    background: #fff;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #1d1d1d;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: 26px;
    display: flex;
    z-index: 99999999;
    padding: 30px 20px;
    box-sizing: border-box;
    box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
}

.subscribe-form-modal h1 {
    font-family: "Graphik LCG Light";
    font-weight: normal;
    font-size: 30px;
    line-height: 45px;
    margin: 0;
    width: 100%;
    margin-top: -15px;
    padding-bottom: 12px;
}

.subscribe-form-modal p {
    font-family: "Graphik LCG Light";
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    text-align: left;
    margin: 0;
    margin-top: -20px;
    /*margin-bottom: 10px;*/
}

.subscribe-form-modal button {
    font-family: "Graphik LCG Medium";
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    height: 55px;
    background: #146957;
    border-radius: 5px;
    border: none;
    color: #fff;
    cursor: pointer;
    width: 100%;
    position: relative;
    top: 10px;
}

.subscribe-form-modal input {
    width: 100% !important;
    margin-left: 0!important;
    margin-bottom: 0!important;
    margin-top: 10px!important;
}

@media screen and (min-width: 769px) {
    .subscribe-form-modal input {
        width: 100% !important;
        margin-left: 0!important;
        margin-bottom: 0!important;
        margin-top: -12px!important;
    }
}

.subscribe-form {
    height: auto!important;
}

@media screen and (max-width: 992px) and (min-width: 554px) {
    .subscribe-form {
        height: 650px!important;
    }
}

@media screen and (max-width: 768px) {
    .subscribe-form-modal {
        width: calc(100vw - 30px);
        height: 461px;
        padding-left: 13px!important;
        padding-right: 13px!important;
        box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
    }
    .subscribe-form-modal h1 {
        font-size: 21px;
        margin-top: -15px;
    }
    .subscribe-form-modal p {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0;
    }
    .subscribe-form-modal input {
        margin-top: 0px!important;
    }
    .subscribe-form {
        height: 650px!important;
    }
    .subscribe-form-container {
        height: 800px;
    }
    .subscribe-form__phone-number-block {
        height: 50px!important;
    }
}

.subscribe-form-modal input::placeholder {
    color: #1D1D1D!important;
    opacity: 0.5!important;
}

.subscribe-form-div {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    top: 5px;
}

.sub-backdrop {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(235, 235, 235, 0.8);
    opacity: 0;
    display: none
}
