.device-460 {
    position: relative;
    width: 100vw;
    margin-bottom: 68px;
    padding-left: 20px;
    display: block!important;
    max-width: 460px;
}

.device-460:first-child {
    margin-top: 70px;
}

.device-460:last-child {
    margin-bottom: 0;
}

.device-460__img {
    position: absolute;
    right: 0;
    z-index: 2;
    top: 0;
    width: 192px!important;
    height: unset!important;
}

.device-460 .health-info__title {
    width: 136px;
    height: unset;
    font-size: 60px;
    margin-top: 55px;
    margin-bottom: 10px;
    position: relative;
    z-index: 3;
}

.device-460 .health-info__description {
    font-size: 14px;
    width: 198px;
    height: unset;
    position: relative;
    z-index: 3;
}

.device-460__ellipse {
    width: 211px;
    height: 211px;
    border-radius: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.device-460__ellipse_first {
    background: #F3F7F6;
}

.device-460__ellipse_second {
    background: rgba(205, 49, 70, .05);
}

.device-460__ellipse_third {
    background: #F5FAF3;
}

.device-460__ellipse_fourth {
    background: #FEF9F0;
}

.health-info__title_small {
    font-size: 24px!important;
}

@media screen and (max-width: 992px) {
    .health-info-mobile {
        height: auto;
    }
    .health-info-mobile-container {
        height: auto;
    }
}