@font-face {
    font-family: 'Graphik LCG';
    src: url('../public/fonts/GraphikLCG-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LCG';
    src: url('../public/fonts/GraphikLCG-SuperItalic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LCG';
    src: url('../public/fonts/GraphikLCG-SemiboldItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LCG';
    src: url('../public/fonts/GraphikLCG-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LCG Super';
    src: url('../public/fonts/GraphikLCG-Super.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LCG Thin';
    src: url('../public/fonts/GraphikLCG-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LCG';
    src: url('../public/fonts/GraphikLCG-ExtralightItalic.woff2') format('woff2');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LCG Regular';
    src: url('../public/fonts/GraphikLCG-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LCG';
    src: url('../public/fonts/GraphikLCG-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LCG Light Italic';
    src: url('../public/fonts/GraphikLCG-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LCG';
    src: url('../public/fonts/GraphikLCG-BlackItalic.woff2') format('woff2');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LCG Medium';
    src: url('../public/fonts/GraphikLCG-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LCG Light';
    src: url('../public/fonts/GraphikLCG-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LCG';
    src: url('../public/fonts/GraphikLCG-Semibold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LCG';
    src: url('../public/fonts/GraphikLCG-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LCG Extralight';
    src: url('../public/fonts/GraphikLCG-Extralight.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LCG';
    src: url('../public/fonts/GraphikLCG-ThinItalic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LCG';
    src: url('../public/fonts/GraphikLCG-RegularItalic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

.superset__title-eng-svg {
    width: 136px;
}

::-webkit-scrollbar { /* chrome based */
    width: 0px;  /* ширина scrollbar'a */
    background: transparent;  /* опционально */
}

html {
    -ms-overflow-style: none;  /* IE 10+ */
    scrollbar-width: none; /* Firefox */
}

p, span, h1, h2, h3, h4, h5, h6 {
    color: #1D1D1D;
}

.loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading__circle {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: #146957;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-name: circle-animation;
}

@keyframes circle-animation {
    0% {
        width: 50px;
        height: 50px;
        border-radius: 50px;
    }
    50% {
        width: 100px;
        height: 100px;
        border-radius: 100px;
    }
    100% {
        width: 50px;
        height: 50px;
        border-radius: 50px;
    }
}

body {
    overflow-x: hidden;
    overflow-y: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    scroll-behavior: smooth;
    position: relative;
}

#app {
    overflow-x: hidden;
    position: relative;
}

header {
    max-width: 1326px;
    width: 100vw;
    height: 89px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 109px;
    padding-left: 114px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*position: fixed;*/
}

.logo-after-scroll {
    position: fixed;
    width: 60px;
    height: 60px;
    left: 40px;
    top: 25px;
    z-index: 9999999;
    opacity: 0;
    transition: opacity .5s;
    display: none;
}

.desktop-burger {
    position: fixed;
    right: 44px;
    top: 41px;
    z-index: 9999999;
    opacity: 0;
    transition: opacity .5s;
    cursor: pointer;
}

.desktop-burger-close {
    position: fixed;
    right: 44px;
    top: 41px;
    z-index: 999999999;
    opacity: 0;
    transition: opacity .5s;
    cursor: pointer;
    display: none;
}

.site-navigation-after-scroll {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10000;
    background-color: #fff;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    transition: opacity .5s;
    z-index: 99999999;
}

.header__navigation {
    display: inline-flex;
    width: 342px;
    height: 26px;
    right: 400px;
    justify-content: space-between;
    font-family: "Graphik LCG Light";
    align-items: center;
    margin-left: 340px;
}

@media screen and (max-width: 1220px) {
    .header__navigation {
        margin-left: 240px;
    }
}

@media screen and (max-width: 1130px) {
    .header__navigation {
        margin-left: 140px;
    }
}

.header__button-subscribe {
    width: 160px;
    min-width: 160px;
    height: 42px;
    background-color: #146957;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    border-radius: 100px;
    border-color: #146957;
    font-family: "Graphik LCG Light";
    font-size: 16px;
    line-height: 25px;
    cursor: pointer;
    -webkit-box-shadow: inset 0 0 0 1px #146957;
    margin-left: 100px;
    transition: color .5s, background-color .5s;
}

.header__button-subscribe:hover {
    background-color: #fff;
    color: #146957;
}

a {
    position: relative;
    color: black;
    cursor: pointer;
    line-height: 1;
    text-decoration: none;

}

a:after {
    display: block;
    position: absolute;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #146957;
    content: "";
    transition: width 0.3s ease-out;
}

a:hover:after,
a:focus:after {
    width: 100%;
}


.first-screen {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    opacity: 0;
}

.first-screen main {
    width: 1105px;
    height: 594px;
    margin: auto;
    box-sizing:border-box;
    position: relative;
    top: 50px;
    z-index: 1000;
}

.dish__shadow dish__shadow_static {
    filter: blur();
}

.first-screen__title {
    font-size: 36px;
    line-height: 50px;
    text-align: left;
    font-family: "Graphik LCG Extralight";
    width: 581px;
    /*height: 156px;*/
    font-weight: normal;
    margin-bottom: 0;
    position: relative;
}

.first-screen__text {
    font-family: "Graphik LCG Extralight";
    font-size: 16px;
    line-height: 25px;
    width: 484px;
    height: 78px;
    margin-top: 22px;
    position: relative;
}

.watch-video {
    width: 191px;
    height: 46px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    border-radius: 100px;
    font-family: "Graphik LCG Light";
    font-size: 14px;
    line-height: 25px;
    cursor: pointer;
    box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.1);
    opacity: 1;
    margin-top: 39px;
    transition: background-color .5s, color .5s;
    position: relative;
}

.watch-video__button-play {
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -webkit-box-shadow: inset 0 0 0 1px #146957;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    position: relative;
    right: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: -webkit-box-shadow .5s;
}

.watch-video__button-play svg {
    fill: #146957;
    transition: fill .5s;
}

.watch-video:hover {
    background-color: #146957;
}

.watch-video:hover .watch-video__button-play {
    -webkit-box-shadow: inset 0 0 0 1px #fff;
}

.watch-video:hover span {
    color: #fff;
}

.watch-video:hover .watch-video__button-play svg {
    fill: #fff;
}

.watch-video-popup {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: none;
    justify-content: center;
    align-items: center;
    cursor: auto;
    z-index: 100000;
}

.watch-video-popup video {
    max-width: 0;
    max-height: 0;
    opacity: 0;
}

#dish {
    float: right;
    width: 600px;
    height: 600px;
    position: relative;
    bottom: 400px;
}

#dish canvas {
    z-index: 1000;
    position: absolute;
}

.spiro-background {
    position: absolute;
    margin-left: 150px;
    margin-top: 15px;
    opacity: 0;
}

.dish__shadow {
    position: absolute;
    bottom: 50px;
    left: 100px;
    opacity: 0;
    filter: blur(20px);
}

.dish__shadow_static {
    opacity: 1 !important;
    bottom: -80px !important;
}

.food-as-medicine {
    width: 100%;
    height: 2452px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1000;
}

.blue-ellipse {
    width: 2448px;
    height: 2452px;
    -moz-border-radius: 2448px;
    -webkit-border-radius: 2448px;
    border-radius: 2448px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1000;
    top: 50vh;
    background-color: white;
    transition: background-color 1.5s;
    overflow: hidden;
}

.blue-ellipse_active {
    background: #f3f8f7;
}

.white-ellipse {
    width: 1326px;
    height: 1326px;
    background: #fff;
    -moz-border-radius: 1326px;
    -webkit-border-radius: 1326px;
    border-radius: 1326px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.white-ellipse p {
    z-index: 1000;
}

.loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading__circle {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: #146957;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-name: circle-animation;
}

@keyframes circle-animation {
    0% {
        width: 50px;
        height: 50px;
        border-radius: 50px;
    }
    50% {
        width: 100px;
        height: 100px;
        border-radius: 100px;
    }
    100% {
        width: 50px;
        height: 50px;
        border-radius: 50px;
    }
}

.first-screen__static-dish {
    float: right;
    width: 500px;
    height: 500px;
    position: relative;
    bottom: 400px;
    display: none;
}

.first-screen__spiro-dish_static {
    position: relative;
    top: -50px;
    left: -25px;
}

.dish__dessert {
    width: 261px;
    height: 267px;
    z-index: -100;
}

.dish__table-dish {
    width: 536px;
    height: 525px;
}

.dish__flying-dish {
    width: 450px;
    height: 450px;
    justify-content: center;
    align-items: center;
    display: none;
    left: calc(50% - 225px);
    opacity: 0;
    z-index: -100;
}

.dish__flying-dish img {
    position: absolute;
}

.dish__table-dish {
    opacity: 0;
    z-index: -1000;
}

.dish__dessert {
    opacity: 1;
}

.food-as-medicine__main-text {
    position: absolute;
    top: 225px;
    width: 470px;
    height: 90px;
    text-align: center;
    font-family: "Graphik LCG Light";
    font-size: 30px;
    line-height: 45px;
    opacity: 0;
    margin-top: 0;
}

.food-as-medicine__text-under-main-text {
    width: 508px;
    height: 51px;
    font-family: "Graphik LCG Light";
    font-size: 16px;
    line-height: 25px;
    position: absolute;
    text-align: center;
    top: 335px;
    opacity: 0;
    margin-top: 0;
}

.food-as-medicine__text-under-main-text_english {
    top: 300px;
}

.food-as-medicine__dish-with-dessert {
    position: absolute;
    width: 536px;
    height: 525px;
    opacity: 0;
}

.food-as-medicine_little-text {
    display: flex;
    align-items: center;
    background: #F3F8F7;
    position: absolute;
    font-family: "Graphik LCG Light";
    border-radius: 7px;
    font-size: 16px;
    opacity: 0;
    margin: 0;
    box-sizing: border-box;
}

.food-as-medicine_little-text img {
    margin-left: 20px;
    margin-right: 11px;
}

.food-as-medicine_little-text span {
    margin-right: 20px;
}

.food-as-medicine__get-energy {
    /*width: 228px;*/
    height: 56px;
    top: 470px;
    left: 140px;
}

.food-as-medicine__better-digestion {
    /*width: 271px;*/
    height: 56px;
    top: 636px;
    left: 64px;
}

.food-as-medicine__clear-skin {
    /*width: 197px;*/
    height: 56px;
    top: 802px;
    left: 171px;
}

.food-as-medicine__stamina {
    /*width: 279px;*/
    height: 56px;
    top: 470px;
    /*right: 75px;*/
    left: 972px;
}

.food-as-medicine__normal-weight {
    /*width: 220px;*/
    height: 56px;
    /*right: 99px;*/
    top: 636px;
    left: 1007px;
}

.food-as-medicine__strengthened-immunity {
    /*width: 256px;*/
    height: 56px;
    top: 802px;
    /*right: 98px;*/
    left: 972px;
}

.food-as-medicine__concentration {
    position: absolute;
    /*width: 278px;*/
    height: 56px;
    bottom: 302px;
}

@media screen and (max-width: 1200px) {
    .food-as-medicine__better-digestion {
        left: 100px;
    }
    .food-as-medicine__stamina {
        right: 100px;
        left: unset;
    }
}

@media screen and (max-width: 1100px) {
    .food-as-medicine__better-digestion {
        left: 120px;
    }
    .food-as-medicine__stamina {
        right: 120px;
        left: unset;
    }
    .food-as-medicine__normal-weight {
        right: 130px;
        left: unset;
    }
    .food-as-medicine__strengthened-immunity {
        right: 130px;
        left: unset;
    }
}

.our-philosophy {
    height: 381px;
    align-self: flex-end;
    position: relative;
    bottom: 768px;
    width: 0;
    opacity: 0;
    z-index: 1000;
}

.our-philosophy__background {
    background-color: white;
    height: 315px;
    width: 872px;
    position: absolute;
    z-index: -100;
    bottom: 0;
    -webkit-box-shadow: 10px 10px 30px rgba(0,0,0,.08);
    border-radius: 5px;
}

.our-philosophy__spiro-photo {
    float: right;
    z-index: 1000;
    opacity: 0;
    margin-top: 4px;
}

.our-philosophy__background img {
    margin: 48px 0 30px 40px;
    opacity: 0;
    /*margin-top: 48px;*/
}

.our-philosophy__background p {
    font-family: "Graphik LCG Light";
    width: 382px;
    /*height: 99px;*/
    font-size: 22px;
    line-height: 33px;
    margin-left: 40px;
    /*margin-top: -20px;*/
    margin-top: 0;
    opacity: 0;
}
.our-philosophy__background p b {
    font-family: "Graphik LCG Regular";
}

.our-philosophy__background span {
    font-family: "Graphik LCG Light";
    margin-left: 40px;
    opacity: 0;
}

.how-it-works-container {
    background: #146957;
    width: calc(100vw);
    height: 662px;
    position: relative;
    top: -682px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.how-it-works-text {
    width: 243px;
    height: 45px;
    font-family: "Graphik LCG Light";
    font-size: 30px;
    line-height: 45px;
    color: #fff;
    position: absolute;
    top: 120px;
    margin: 0;
}

.how-it-works {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 1068px;
    height: 317px;
    position: relative;
    top: -8px;
}

.how-it-works__info-circle {
    width: 116px;
    height: 116px;
    background: #127d69;
    -moz-border-radius: 116px;
    -webkit-border-radius: 116px;
    border-radius: 116px;
    align-self: flex-start;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.how-it-works__info {
    width: 264px;
    height: 232px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.how-it-works-schedule {
    margin-left: 7px;
}

.how-it-works__info p {
    position: absolute;
    text-align: center;
    font-family: "Graphik LCG Light";
    color: #fff;
    font-size: 16px;
    line-height: 25px;
    margin-top: 34px;
}

.how-it-works__info-food {
    width: 264px;
    height: 101px;
    margin-top: 0!important;
    top: 168px;
}

.how-it-works__info-superfood {
    width: 345px;
    height: 101px;
    margin-top: 0!important;
    top: 168px;
}

.how-it-works__info-schelude {
    margin-top: 0!important;
    width: 272px;
    height: 76px;
    top: 168px
}

@-moz-document url-prefix() {
    .how-it-works__info p {
        top: 160px;
    }
}

@media screen and (max-width: 1140px) {
    .how-it-works__info p {
        width: 250px;
    }
}


.how-it-works-marquee {
    position: absolute;
    align-self: flex-end;
    border: 1px solid #127d69;
    height: 104px;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    bottom: 0;
}

.how-it-works-marquee span {
    color: #fff;
    font-family: "Graphik LCG Light";
    font-size: 30px;
    line-height: 45px;
    display: inline-block;
    animation: marquee 150s infinite;
}

@keyframes marquee {
    from   { transform: translate(0, 0); }
    to { transform: translate(-50%, 0); }
}

.food-menu-container {
    width: 100vw;
    height: 867px;
    margin-top: -680px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: -130px;
}

.food-menu {
    width: 840px;
    height: 629px;
    position: absolute;
}

.food-menu__header {
    font-size: 30px;
    font-family: "Graphik LCG Light";
}

.food-menu__weeks {
    width: 264px;
    height: 114px;
    background: #f5f5f5;
    border-radius: 10px;
    font-size: 16px;
    font-family: "Graphik LCG Light";
    /*margin-top: 31px;*/
    position: relative;
    top: 46px;
}

.food-menu__current-week,
.food-menu__next-week {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50%;
    cursor: pointer;
    box-sizing: content-box;
}

.food-menu__current-week span,
.food-menu__next-week span {
    margin-left: 11px;
}

.food-menu__user-choice {
    height: 30px;
    width: 2px;
    background: #146957;
    opacity: 0;
    transition: opacity .5s;
}

.food-menu__days {
    width: 264px;
    height: 285px;
    background: #f5f5f5;
    border-radius: 10px;
    font-size: 16px;
    font-family: "Graphik LCG Light";
    margin-top: 55px;
}

.food-menu__monday,
.food-menu__tuesday,
.food-menu__wednesday,
.food-menu__thursday,
.food-menu__friday {
    display: flex;
    align-items: center;
    width: 100%;
    height: 20%;
    cursor: pointer;
    box-sizing: content-box;
}

.food-menu__monday span,
.food-menu__tuesday span,
.food-menu__wednesday span,
.food-menu__thursday span,
.food-menu__friday span {
    margin-left: 11px;
}

.food-menu__performance,
.food-menu__beauty {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50%;
    cursor: pointer;
    box-sizing: content-box;
}

.food-menu__performance span,
.food-menu__beauty span {
    margin-left: 11px;
}

.food-menu__times {
    width: 425px;
    height: 37px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    right: 80px;
}

.food-menu__breakfast,
.food-menu__lunch,
.food-menu__dinner,
.food-menu__superset {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #EBEBEB;
    border-radius: 100px;
    font-size: 15px;
    font-family: "Graphik LCG Regular";
    cursor: pointer;
    transition: background-color .5s, border-color .5s;
    border: 1px solid #EBEBEB;
}

.food-menu__breakfast {
    width: 105px;
    height: 37px;
}
.food-menu__lunch {
    width: 83px;
    height: 37px;
}
.food-menu__dinner {
    width: 85px;
    height: 37px;
}
.food-menu__superset {
    width: 122px;
    height: 37px;
    opacity: 0;
}

.food-menu__breakfast_active,
.food-menu__lunch_active,
.food-menu__dinner_active,
.food-menu__superset_active {
    border: 1px solid #146957;
    background: #fff;
}

.food-menu__dishes {
    width: 500px;
    height: 500px;
    position: absolute;
    top: 75px;
    right: 3.2px;
    display: flex;
    align-items: center;
}


.food-menu__dish {
    background: #FFFFFF;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    position: absolute;
    transition: filter 1s;
}

.food-menu__dish img {
    max-width: 100.5% !important;
}

.food-menu__dishes * {
    transition: opacity .75s;
}

.food-menu__dish-current {
    z-index: -100;
    width: 418px;
    height: 501px;
    position: absolute;
}

.food-menu__dish-next {
    left: 125px;
    z-index: -1000;
    width: 370px;
    height: 442px;
    position: absolute;
    filter: blur(2.5px);
}

.food-menu__dish-copy {
    z-index: -2000;
    width: 370px;
    height: 442px;
    position: absolute;
    left: 525px;
}

.food-menu__dish img {
    width: 418px;
    height: 418px;
}

.food-menu__dish-next img,
.food-menu__dish-copy img {
    width: 370px;
    height: 370px;
}

.food-menu__dish-description {
    height: 52px;
    font-family: "Graphik LCG Light";
    font-size: 16px;
    line-height: 25px;
    margin-left: 20px;
    margin-top: 15px;
}

.food-menu__second-image, .food-menu__third-image {
    transition: opacity .5s;
    display: none;
    border-radius: 5px;
}

.food-menu__first-image {
    /*position: absolute;*/
    transition: opacity .5s;
}

.food-menu__image {
    transition: opacity .5s;
}

.food-menu__active-image {
    display: block;
}

.food-menu__button-left {
    position: absolute;
    left: 340px;
    top: 600px;
}

.food-menu__button-right {
    position: absolute;
    left: 441px;
    top: 600px;
}

.food-menu__buttons {
    cursor: pointer;
    bottom: -50px;
    width: 88px;
    height: 42px;
    background: #FFFFFF;
    box-shadow: 3px 3px 40px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.food-menu__buttons svg {
    stroke: #1D1D1D;
    transition: stroke .2s;
}

.food-menu__buttons:hover svg {
    stroke: #146957;
}

.white-block {
    width: 500px;
    height: 700px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    z-index: -10;
    left: -190px;
}

.right-white-block {
    width: 500px;
    height: 700px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    z-index: -10;
    right: -500px;
}

.subscribe-form-container {
    transition: background-color;
    background-image: url(../public/subscribeForm/form-bg.jpg), url(../public/subscribeForm/background-man.jpg);
    background-size: cover;
    background-position: center center;
    padding: 50px 0;
    height: 891px;
    /*margin-top: 10px;*/
    width: calc(100vw);
    display: flex;
    justify-content: center;
}

.subscribe {
    width: 1300px;
    height: 100%;
    position: relative;
    top: -50px;
    box-sizing: border-box;

}

.subscribe-form-container-man {
    background-image: url(../public/subscribeForm/background-man.jpg);
    background-size: cover;
    background-position: center center;
    height: 902px;
    width: calc(100vw);
}

.subscribe-form__not-all-fields {
    position: absolute;
    width: 378.5px;
    height: 121px;
    left: 580px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    bottom: 20px;
}

.subscribe-form__not-all-fields * {
    position: absolute;
}

.subscribe-form__not-all-fields h5 {
    color: #CD3146;
    font-size: 16px;
    font-family: "Graphik LCG Medium";
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    top: 18px;
    left: 18px;
}

.subscribe-form__not-all-fields p {
    color: #1d1d1d;
    font-family: "Graphik LCG Light";
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    top: 51px;
    left: 18px;
    line-height: 25px;
}

.subscribe-form {
    width: 468px;
    height: 814px;
    background: #fff;
    margin-top: 140px;
    padding: 30px 20px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    transition: background-color 2s;
    margin-left: 100px;
    border-radius: 5px;
    box-sizing: border-box;
    padding-bottom: 50px;
}

.subscribe-form__header {
    font-family: "Graphik LCG Light";
    font-size: 25px;
    margin-top: 20px;
    margin-left: 0;
    font-weight: normal;
    margin-bottom: 10px;
}

.subscribe-form__main-block {
    width: 428px;
    /*height: 696px;*/
    position: relative;
    top: -10px;
}

.subscribe-form__line {
    width: 428px;
    border-bottom: 1px solid #dddddd;
    display: flex;
    align-items: center;
    opacity: 0;
}

.subscribe-form__your-name,
.subscribe-form__phone-number,
.subscribe-form__town {
    width: 304px;
    height: 51px;
    margin-left: 81px;
    border: 1px solid #000;
    box-sizing: border-box;
    border-radius: 5px;
    padding-left: 17px;
    font-family: "Graphik LCG Light";
    font-size: 16px;
    background: transparent;
}

@media screen and (min-width: 1025px) {
    .subscribe-form__main-block-second .subscribe-form__header {
        margin-bottom: 20px;
    }
    .subscribe-form__your-name {
        margin-top: 10px;
    }
    .subscribe-form__line-monday, .subscribe-form__line-wednesday {
        top: 420px;
    }
    .subscribe-form__about-delivery {
        margin-top: 30px;
    }
}

.subscribe-form__thanks-for-subscribe {
    width: 483px;
    height: 266px;
    background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #1d1d1d;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: 26px;
    background: #fff;
    opacity: 0;
    display: none;
    z-index: 99999999;
}

.subscribe-form__thanks-for-subscribe h1 {
    font-family: "Graphik LCG Light";
    font-weight: normal;
    font-size: 30px;
    line-height: 45px;
    margin: 0;
}

.subscribe-form__thanks-for-subscribe p {
    font-family: "Graphik LCG Light";
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    margin: 0;
}

.subscribe-form__thanks-for-subscribe button {
    font-family: "Graphik LCG Medium";
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    width: 428px;
    height: 55px;
    background: #146957;
    border-radius: 5px;
    border: none;
    color: #fff;
    cursor: pointer;
}

.subscribe-form__your-name::placeholder,
.subscribe-form__phone-number::placeholder,
.subscribe-form__street::placeholder,
.subscribe-form__home::placeholder,
.subscribe-form__flat::placeholder,
.subscribe-form__your-comments::placeholder {
    opacity: .5;
}

.subscribe-form__town {
    padding-left: 0;
}

.subscribe-form__town {
    border-color:  #fff;
    color: #fff;
}

.pp-zero__popup {
    z-index: 99999999999;
}

.subscribe-form__town .Dropdown-placeholder,
.subscribe-form__monday .Dropdown-placeholder,
.subscribe-form__wednesday .Dropdown-placeholder {
    color: #fff;
}

.subscribe-form__your-name::placeholder,
.subscribe-form__phone-number::placeholder,
.subscribe-form__town::placeholder,
.subscribe-form__street::placeholder,
.subscribe-form__home::placeholder,
.subscribe-form__flat::placeholder,
.subscribe-form__monday::placeholder,
.subscribe-form__wednesday::placeholder,
.subscribe-form__your-comments::placeholder {
    color: #fff;

}

.subscribe-form__main-block-second h6 {
    color: #fff;
}

.subscribe-form__main-block-second input,
.subscribe-form__main-block-second textarea,
.subscribe-form__main-block-second .subscribe-form__subscribe-button {
    border-color: #fff;
}

.subscribe-form__main-block-second input {
    color: #fff;
}

.subscribe-form__main-block-second .subscribe-form__subscribe-button {
    background-color: #fff;
}

.subscribe-form__main-block-second .subscribe-form__subscribe-button span {
    color: #000;
}

.subscribe-form__main-block-second .subscribe-form__subscribe-button:hover {
    background-color: transparent;
}

.subscribe-form__main-block-second .subscribe-form__subscribe-button:hover {
    border-color: #fff;
}

.subscribe-form__main-block-second .subscribe-form__subscribe-button:hover span {
    color: #fff;
}

.subscribe-form__street,
.subscribe-form__home,
.subscribe-form__flat {
    border: 1px solid #000;
    box-sizing: border-box;
    border-radius: 5px;
    padding-left: 17px;
    font-family: "Graphik LCG Light";
    font-size: 16px;
    background: transparent;
}

.subscribe-form__phone-number {
    margin-left: 57px;
}

.subscribe-form__your-name {
    margin-bottom: 10px;
}


.subscribe-form__address {
    width: 304px;
    height: 122px;
    margin-left: 54px;
    display: grid;
    grid-template-columns: 147px 68.52px 68.52px;
    grid-column-gap: 10px;
    grid-template-rows: 51px 51px;
    grid-row-gap: 20px;
}

.subscribe-form__town {
    /*margin-bottom: 70px;*/
    margin-left: 0;
    grid-column: 1 / 4;
}

.subscribe-form__monday {
    margin-left: 54px;
    width: 147px;
    height: 51px;
    display: inline-block;
    box-sizing: border-box;
    font-family: "Graphik LCG Light";
    font-size: 16px;
    cursor: pointer;
}

.subscribe-form__monday .Dropdown-control,
.subscribe-form__wednesday .Dropdown-control{
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 0;
    padding-left: 17px;
    display: flex;
    align-items: center;
    border: 1px solid #fff;
    border-radius: 5px;
}

.subscribe-form__wednesday {
    display: inline-block;
    width: 147px;
    height: 51px;
    margin-left: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: "Graphik LCG Light";
    font-size: 16px;
    cursor: pointer;
}

.subscribe-form__your-comments {
    margin-left: 22px;
    border: 1px solid #000;
    box-sizing: border-box;
    border-radius: 5px;
    padding-left: 17px;
    font-family: "Graphik LCG Light";
    font-size: 16px;
    width: 304px;
    height: 93px;
    padding-top: 8px;
    line-height: 25px;
    background: transparent;
    color: #fff;
}

.subscribe-form__line h6,
.subscribe-form__line span,
.subscribe-form__header
{
    transition: color .5s;
}

.subscribe-form__title {
    font-family: "Graphik LCG Light";
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
}

.subscribe-form__set-date {
    position: relative;
    width: 304px;
    height: 51px;
    margin-left: 27px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: "Graphik LCG Light";
    font-weight: normal;
    border: 1px solid #1D1D1D;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: transparent;
    cursor: pointer;
}

.subscribe-form .Dropdown-menu {
    border: 1px solid #1D1D1D;
    border-radius: 5px;
    margin-top: 6px;
}


.subscribe-form__set-date .Dropdown-control,
.subscribe-form__town .Dropdown-control{
    width: 100%;
    height: 100%;
    padding: 0;
    padding-left: 17px;
    display: flex;
    /*justify-content: center;*/
    align-items: center;
    background-color: transparent;
    border: 0;
    cursor: pointer;
}

.subscribe-form__set-date .Dropdown-arrow,.subscribe-form__set-date.is-open .Dropdown-arrow,
.subscribe-form__wednesday .Dropdown-arrow,.subscribe-form__wednesday.is-open .Dropdown-arrow,
.subscribe-form__monday .Dropdown-arrow,.subscribe-form__monday.is-open .Dropdown-arrow
{
    border-color: #1d1d1d transparent;
    top: 24px;
    right: 22px;
}

.subscribe-form__wednesday .Dropdown-arrow,.subscribe-form__wednesday.is-open .Dropdown-arrow,
.subscribe-form__monday .Dropdown-arrow,.subscribe-form__monday.is-open .Dropdown-arrow {
    border-color: #fff transparent;
}

.subscribe-form__town .Dropdown-arrow, .subscribe-form__town.is-open .Dropdown-arrow {
    border-color: #fff transparent;
    top: 24px;
    right: 22px;
}

.subscribe-form__town .Dropdown-arrow {
    border-color: #fff transparent;
    top: 24px;
    right: 22px;
}

.subscribe-form__set-date .Dropdown-option,
.subscribe-form__town .Dropdown-option,
.subscribe-form__wednesday .Dropdown-option,
.subscribe-form__monday .Dropdown-option {
    height: 50px;
    display: flex;
    align-items: center;
    font-family: "Graphik LCG Light";
}

.subscribe-form input:focus {
    outline: none;
}

.subscribe-form__set-date .Dropdown-option:hover,
.subscribe-form__town .Dropdown-option:hover{
    background-color: #d8e7e4;
}

.subscribe-form__set-date .Dropdown-option.is-selected,
.subscribe-form__town .Dropdown-option.is-selected,
.subscribe-form__delivery-time .Dropdown-option.is-selected{
    background: #146957;
    color: #fff;
}

.select-woman .is-selected {
    background: #006d57 !important;
}

.select-man .is-selected {
    background: #007597 !important;
}

.select-man {
    /*margin-left: 102px;*/
}

.subscribe-form__set-date:focus {
    outline: none;
}

.subscribe-form__circle {
    width: 25px;
    height: 25px;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
    border-radius: 25px;
    border: 1px solid #4E4E4E;
    cursor: pointer;
    background: #fff;
    box-sizing: border-box;
}

.subscribe-form__circle_transparent {
    background: transparent !important;
    border: 1px solid #fff !important;
}

.subscribe-form__line-monday {
    position: absolute;
    color: #fff;
    font-family: "Graphik LCG Light";
    opacity: .5;
    bottom: 284px;
    left: 122px;
}

.subscribe-form__line-wednesday {
    position: absolute;
    color: #fff;
    font-family: "Graphik LCG Light";
    opacity: 0.5;
    bottom: 284px;
    left: 279px;
}

/*.subscribe-form__address .is-selected.select-woman {*/
/*    background-color: #006d57;*/
/*}*/

.subscribe-form__woman .subscribe-form__checked-mark,
.subscribe-form__man .subscribe-form__checked-mark{
}

.subscribe-form__checked-mark {
    opacity: 0;
    position: relative;
    left: 5px;
    top: 2.5px;
}

.subscribe-form__rectangle .subscribe-form__checked-mark {
    left: 0;
    top: 0;
}

.subscribe-form__label input {
    display: none;
}

.subscribe-form__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: -10px;
}

.subscribe-form__label span {
    font-size: 16px;
    font-family: "Graphik LCG Light";
}

.subscribe-form__rectangle {
    width: 23px;
    height: 23px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.subscribe-form__label {
    cursor: pointer;
}

.subscribe-form__type-program-description {
    position: relative;
    display: block;
    top: 8px;
    opacity: .5
}

.subscribe-form__set-date img {
    margin-left: 114px;
    cursor: pointer;
}

.subscribe-form__about-delivery {
    font-size: 15px;
    font-family: "Graphik LCG Light";
    font-weight: normal;
    margin-left: 44px;
    margin-top: 10px;
    width: 340px;
    height: 20px;
    text-align: center;
    display: none;
}

.subscribe-form__subscribe-button {
    width: 428px;
    height: 55px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #146957;
    border-radius: 5px;
    font-size: 16px;
    font-family: "Graphik LCG Regular";
    font-weight: normal;
    color: #fff;
    position: relative;
    bottom: 22px;
    cursor: pointer;
    border: 1px solid #146957;
    transition: background-color .5s, color .5s;
    top: 30px;
    /*margin-left: 20px;*/
}

.subscribe-form__subscribe-button span {
    color: #fff;
    transition: color .5s;
}

.subscribe-form__subscribe-button:hover span {
    color: #146957;
}

.subscribe-form__subscribe-button-seted-sex {
    background-color: #fff;
    border: 1px solid #fff;
}

.subscribe-form__subscribe-button-seted-sex span {
    color: #000;
}

.subscribe-form__subscribe-button:hover {
    background-color: #fff;
    border: 1px solid #006d57;
}


.subscribe-form__subscribe-button-seted-sex:hover {
    background-color: transparent;
    color: #fff;
    transition: background-color .5s, color .5s;
    border: 1px solid #fff;
}

.subscribe-form__subscribe-button-seted-sex:hover span {
    color: #fff;
}

.health-info {
    width: 100vw;
    height: 2444px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.health-info__header {
    font-size: 30px;
    font-family: "Graphik LCG Extralight";
    font-weight: normal;
    width: 762px;
    height: 84px;
    margin-top: 120px;
    text-align: center;
    line-height: 42px;
}

.health-info__block {
    position: relative;
    top: -40px;
}

.health-info__first {
    width: 984px;
    height: 417px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.parallax-outer {
    margin: 0;
}

.health-info__circle {
    width: 308px;
    height: 308px;
    -moz-border-radius: 308px;
    -webkit-border-radius: 308px;
    border-radius: 308px;
    background: #f3f8f7;
    position: relative;
}

.health-info__title {
    width: 183px;
    height: 108px;
    font-family: "Graphik LCG Thin";
    font-weight: normal;
}

.health-info__title-first {
    font-size: 87.1429px;
    margin-top: 97px;
    margin-left: 70px;
    position: relative;
    top: 50px;
}

.health-info__description {
    width: 305px;
    height: 61px;
    font-family: "Graphik LCG Extralight";
    line-height: 31px;
    font-size: 20px;
}

.health-info__description-first {
    margin-top: -200px;
    margin-left: 70px;
    position: relative;
    top: 50px;
}

.health-info__image-first {
    width: 536px;
    height: 444px;
    position: relative;
}

.health-info__image {
    position: relative;
}

.flying-fruits {
    position: absolute;
    margin-top: 200px;
    z-index: 99999;
    width: 568px;
    height: 541px;
    margin-left: 200px;
}

.flying-fruits img:first-child {
    position: relative;
    top: 0;
    left: 350px
}

.flying-fruits img:last-child {
    position: relative;
    top: 350px;
    left: -200px;
}

.health-info__second {
    width: 909px;
    height: 432px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.health-info__third {
    width: 972px;
    height: 557px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.health-info__fourth {
    width: 909px;
    height: 436px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.health-info__image-second {
    width: 454px;
    height: 432px;
}

.health-info__image-third {
    width: 529px;
    height: 557px;
    margin-top: -100px;
}

.health-info__image-fourth {
    width: 467px;
    height: 523px;
    object-fit: cover;
    /*top: 0 !important;*/
}

.health-info__title-second {
    font-size: 40px;
    margin-top: 124px;
    margin-left: -30px;
    position: relative;
    top: 50px;
}

.health-info__title-third {
    font-size: 87.1429px;
    margin-top: 97px;
    margin-left: 90px;
    position: relative;
    top: 50px;
}

.health-info__title-fourth {
    font-size: 87.1429px;
    margin-top: 124px;
    margin-left: -30px;
    position: relative;
    top: 50px;
}

.health-info__description-second {
    margin-top: -100px;
    margin-left: -30px;
    position: relative;
    top: 50px;
}

.health-info__description-third {
    margin-top: -200px;
    margin-left: 90px;
    position: relative;
    top: 50px;
}

.health-info__description-fourth {
    margin-top: -200px;
    margin-left: -30px;
    position: relative;
    top: 50px;
}

.reviews-container {
    width: calc(100vw);
    position: relative;
    background: #146957;
    height: 605px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 80px;
}

.reviews__carousel {
    position: absolute;
    width: 1098px;
    height: 203px;
    top: 20px;
}

.reviews__carousel .reviews-dot {
    margin-top: -30px !important;
}

@media screen and (max-width: 1200px) {
    .reviews__carousel {
        width: 900px;
    }
}

.reviews {
    height: 365px;
    border: 1px solid black;
    width: 80%;
    display: flex;
    position: absolute;
}

.reviewer {
    height: 203px;
    display: flex;
    align-items: center;
    position: relative;
    top: 10px;
    left: 10px;
}

.reviewer-first {
    left: 180px;
}

.reviewer-second {
    right: 180px;
}

.reviewer-third {
    right: -500px;
}

.reviews-button {
    width: 44px;
    height: 29px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1000;
    position: absolute;
    transition: .2s;
}

.reviews-button:hover {
    background: #fff;
}

.reviews-button:hover svg path {
    stroke: #146957;
}

.reviews-button-left {
    left: -103px;
    top: 100px;
}

.reviews-button-right {
    right: -60px;
    top: 100px;
}

.reviews-wall-left {
    position: absolute;
    height: 100%;
    z-index: 100;
    left: 0;
    background: #146957;
}

.reviews-wall-right {
    position: absolute;
    height: 100%;
    z-index: 100;
    right: 0;
    background: #146957;
}

.reviews-header {
    width: 243px;
    height: 45px;
    font-family: 'Graphik LCG Extralight';
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    color: #fff;
    align-self: flex-start;
    position: absolute;
    margin-top: 130px;
}

.reviewer__image {
    width: 192px;
    height: 192px;
    z-index: -100;
    object-fit: cover;
}


.reviewer__name {
    font-family: 'Graphik LCG Medium';
    font-size: 15px;
    color: white;
    position: relative;
    bottom: 200px;
    left: 224px;
}

.reviewer__text {
    font-family: "Graphik LCG Light";
    /*font-size: 1vw;*/
    color: white;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 100px;
    position: relative;
    top: -240px;
    left: 224px;
    width: 50%;
    margin-top: 0;
}


.instagram-image {
    position: relative;
    top: -251px;
    left: 274px;
    /*display: none !important;*/
    opacity: 0;
}

.reviewer__spiro-symbol {
    position: absolute;
    top: -10px;
    left: 20px;
    z-index: 100;
}

.reviews-dot {
    width: 65px;
    height: 5px;
    background: #EBEBEB;
    box-shadow: 3px 3px 40px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
}

.slick-dots {
    display: flex !important;
    justify-content: center !important;
}

.slick-dots li {
    margin-right: 50px !important;
    opacity: .5;
    top: 62px !important;
    transition: opacity 1s !important;
}

.slick-active {
    opacity: 1 !important;
    transition: opacity 1s !important;
}

@media screen and (max-width: 1300px) {
    .reviews-button-left {
        left: -50px;
    }
    .reviews-button-right {
        right: -30px;
    }
}

@media screen and (max-width: 1150px) {
    .reviewer__text {
        width: 200px;
    }
}

.phones-container {
    background-color: #EBEBEB;
    width: calc(100vw);
    height: 600vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 164px solid #EBEBEB ;
    top: 80px;
}

.phone__static_0 {
    z-index: 10000;
    margin-left: 50px;
}

.phones {
    width: 1300px;
    height: 100vh;
    background-color: #EBEBEB;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.phones__night-image {
    min-width: 1920px;
    max-width: 100vw;
    height: 632px;
    position: absolute;
    top: 0;
    /*left: 0;*/
}

/*.phones__night-image img {*/
/*    !*min-width: calc(1920px);*!*/
/*    !*max-width: calc(100vw + 600px);*!*/
/*    height: 632px;*/
/*    position: absolute;*/
/*    !*left: -400px;*!*/
/*    !*width: calc(100vw + 400px);*!*/
/*    !*min-width: 1920px;*!*/
/*}*/

.phones-container__night {
    min-width: calc(1920px + 200px);
    height: 683px;
    max-width: calc(100vw + 200px);
    /*z-index: 100000;/*/
    position: absolute;
}

#iphone-model {
    width: 600px;
    height: 600px;
    z-index: 1000;
    transition: opacity 1s;
    /*margin-top: -100px;*/
}

.spiro-blick {
    position: fixed;
    width: 500px;
    height: 100vh;
    opacity: 0;
    top: 0;
    left: 0;
    z-index: 99999999;
    display: none;
}

.phone__static {
    opacity: 0;
    max-width: unset !important;
    max-height: unset !important;
    width: 360px;
    height: 673px;
}

.phones__text-about-app {
    position: absolute;
    top: 0;
    width: 759px;
    height: 319px;
    left: 450px;
}

.phones__not-about-food {
    font-family: "Graphik LCG Light";
    font-weight: 300;
    color: #fff;
    line-height: 39px;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 39px;
    letter-spacing: 0.175717px;
    position: relative;
    top: 70px;
    width: 700px;
    height: 119px;
}

.phones__not-about-food span {
    color: #fff;
}

.phones__we-crafted {
    position: relative;
    font-family: "Graphik LCG Extralight";
    font-size: 16px;
    line-height: 25px;
    color: #fff;
    top: 40px;
}

p.phones__we-crafted {
    font-family: 'Graphik LCG Medium';
    margin-bottom: 21px;
}

ul.phones__we-crafted {
    position: relative;
    right: 23px;
    top: 26px;
}

/*тут адаптивность нужна*/
ul.phones__we-crafted li {
    margin-top: 5px;
}

.phones__ellipse {
    width: 484px;
    height: 484px;
    border-radius: 484px;
    background: #fff;
    opacity: .5;
    /*margin-left: 50px;*/
    position: relative;
    top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.phones__text {
    width: 392px;
    height: 223px;
    font-family: "Graphik LCG Extralight";
    font-size: 16px;
    line-height: 25px;
    font-weight: 300;
    position: relative;
}


.phones__text h6 {
    font-family: "Graphik LCG Light";
    font-weight: normal;
    font-size: 30px;
    margin: 0;
    background: #fff;
    display: inline-block;
    height: 33px;
    padding-top: 7px;
}

.phones__text span {
    font-size: 16px;
    position: relative;
    top: 10px;
}

.phones__second img,
.phones__third img,
.phones__fourth img,
.phones__fifth img,
.phones__sixth img {
    max-width: 275px;
    max-width: 275px;
}

.our-team-container {
    width: 100vw;
    height: 951px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
}

.our-team {
    width: 1300px;
    height: 951px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.our-team-carousel {
    width: 1288px;
    height: 420px;
}

.our-team-carousel .dot-our-team {
    margin-top: -15px !important;
}

.slick-dots-our-team li {
    opacity: 1 !important;
    transition: background-color 2s !important;
}

.slick-dots-our-team .slick-active .dot-our-team {
    opacity: 1 !important;
    background-color: #146957 !important;
    transition: background-color 2s !important;
}

.dot-our-team {
    width: 65px;
    height: 5px;
    background: #fff;
    box-shadow: 3px 3px 40px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
}

.our-team__background {
    background: #ebebeb;
    width: 1100px;
    height: 711px;
    position: absolute;
    right: 90px;
}

.our-team__member {
    /*position: absolute;*/
    height: 420px;
 }

.slick-slide, .slick-slide *{ outline: none !important; }

.our-team__member > img {
    z-index: 1000;
    width: 420px;
    height: 420px;
    object-fit: cover;
}

@media screen and (max-width: 1150px) {
    .our-team__member {
        padding-left: 200px;
    }
}


.our-team__background-image {
    /*position: absolute;*/
    position: relative;
    bottom: 350px;
    left: 300px;
    z-index: -10 !important;
    width: 168px !important;
    height: 168px !important;
}

.our-team__button-right {
    position: absolute;
    top: -100px;
    width: 88px;
    height: 42px;
    background: #FFFFFF;
    box-shadow: 3px 3px 40px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: .8;
    transition: opacity.2s;
}

.our-team__button-left {
    position: absolute;
    top: -100px;
    width: 88px;
    height: 42px;
    background: #FFFFFF;
    box-shadow: 3px 3px 40px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    right: 114px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: .8;
    transition: opacity.2s;
}

body {
    margin-left: 0;
    margin-right: 0;
}

.superset-container {
    background: #F5F5F5;
    width: 100vw;
    height: 764px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.superset {
    width: 1300px;
    height: 563px;
    position: absolute;
}

.superset__text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 263px;
    height: 263px;
    position: absolute;
    right: 0;
    z-index: 10;
}

.superset__text img {
    position: absolute;
}

.superset__star {
    animation: superset-star 10s linear infinite;
}

.superset__title {
    position: absolute;
    font-weight: normal;
    font-size: 30px;
    font-family: "Graphik LCG Light";
    position: absolute;
    top: 62px;
    left: 688px;
    margin: 0;
    z-index: 99999;
}

.superset__paragraph {
    position: absolute;
    top: 127px;
    right: 258px;
    width: 353px;
    font-size: 16px;
    font-family: "Graphik LCG Light";
    line-height: 25px;
    margin: 0;
    z-index: 99999;
}

.superset__title_svg {
    position: absolute;
    top: 98px;
    left: 688px;
    z-index: 99999;
}

.superset__title_eng-svg {
    width: 136px;
}

.superset__description {
    width: 476px;
    height: 277px;
    background: #FFFFFF;
    border-radius: 10px;
    position: absolute;
    top: 228px;
    right: 135px;
    box-sizing: border-box;
    padding-left: 18px;
    padding-top: 32px;
    z-index: 99999;
}

.superset__beauty-button {
    width: 107px;
    height: 37px;
    border-radius: 1000px;
    font-size: 16px;
    font-family: "Graphik LCG Light";
    color: #1d1d1d;
    background: #F5F5F5;
    display: inline-block;
    /*justify-content: center;*/
    /*align-items: center;*/
    cursor: pointer;
    transition: background-color .5s, color .5s;
    margin-right: 10px;
    vertical-align: middle;
    text-align: center;
    box-sizing: border-box;
    padding-top: 11.5px;
}

.superset__performance-button {
    width: 162px;
    height: 37px;
    background: #F5F5F5;
    border-radius: 1000px;
    font-size: 16px;
    font-family: "Graphik LCG Light";
    text-align: center;
    display: inline-block;
    /*justify-content: center;*/
    /*align-items: center;*/
    box-sizing: border-box;
    padding-top: 11.5px;
    vertical-align: middle;
    cursor: pointer;
    transition: background-color .5s, color .5s;
}

.superset__button-is-active {
    background: #146957;
    color: #fff;
    font-family: "Graphik LCG Regular";
}

.superset__description-text {
    font-family: "Graphik LCG Light";
    font-size: 16px;
    padding-left: 0;
    width: 455px;
}

.superset__description-text li {
    margin-bottom: 10px;
    list-style: none;
    /*list-style: ;*/
    margin-left: -8px;
}

.superset__description-text li:before {
    content:"·";
    font-size:120px;
    vertical-align:middle;
    line-height:20px;
}

.superset__description-title {
    margin-top: 27px;
    margin-bottom: 20px;
    font-family: "Graphik LCG Light";
    font-size: 16px;
    width: 365px;
    line-height: 25px;
    list-style-position: outside;

}

.superset__elements {
    width: 530px;
    height: 501px;
    position: absolute;
    left: 100px
}

.superset__element {
    display: inline-block;
    width: 418px;
    height: 501px;
    background: #fff;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    position: absolute;
}

.superset__element-description {
    font-family: "Graphik LCG Light";
    line-height: 25px;
    margin-left: 20px;
    margin-right: 20px;
}

.superset__second-element {
    width: 368.5px;
    height: 443px;
    z-index: 0;
    left: 120px;
    top: 30px;
    filter: blur(2px);
    transition: filter 1s;
}

.superset__third-element, .superset__fourth-element, .superset__fifth-element {
    left: 700px;
    width: 368.5px;
    height: 443px;
    z-index: 0;
    top: 30px;
    filter: blur(2px);
    transition: filter 1s;
}

.superset__wall-right {

}

.superset__third-element .superset__image,
.superset__fourth-element .superset__image,
.superset__fifth-element .superset__image {
    width: 370px;
    height: 370px;
}

.superset__wall-right {
    height: calc(100% + 50px);
    width: 700px;
    background: #F5F5F5;
    z-index: 1000;
    position: absolute;
    left: 600px;
    top: -25px;
}

.superset__other-element {
    left: -500px;
}

.superset__buttons {
    width: 88px;
    height: 42px;
    background: #FFFFFF;
    box-shadow: 3px 3px 40px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    stroke: #1D1D1D;
    cursor: pointer;
    position: absolute;
}

.superset__buttons:hover {
    stroke: #146957;
}

.superset__second-element .superset__image {
    width: 370px;
    height: 370px;
}

.superset__first-element {
    z-index: 10;
}

.superset__image {
    width: 418px;
    height: 418px;
    object-fit: cover;
}

.superset__button-left {
    bottom: 0;
    left: 100px;
}

.superset__button-right {
    bottom: 0;
    left: 200px;
}

.superset__wall {
    height: calc(100% + 50px);
    width: 500px;
    background: #F5F5F5;
    z-index: 1000;
    position: absolute;
    left: -450px;
    top: -25px;
}

@keyframes superset-star {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.our-team__button-left:hover, .our-team__button-right:hover {
    opacity: 1;
}

.our-team__text {
    font-family: 'Graphik LCG Extralight';
    font-style: normal;
    font-weight: 300;
    font-size: 45px;
    position: absolute;
    top: 100px;
    left: 0;
}

.our-team__about-member {
    width: 305px;
    height: 151px;
    position: relative;
    background: #fff;
    top: -410px;
    left: 300px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    /*padding-left: 10px;*/
    box-shadow: 3px 3px 40px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    box-sizing: border-box;
}

.our-team__about-member h6 {
    font-family: 'Graphik LCG Medium';
    font-size: 16px;
    width: 100%;
    margin-bottom: 0;
    margin-left: 28px;
    font-weight: normal;
}

.our-team__about-member span {
    color: #117592;
    font-size: 16px;
    font-family: 'Graphik LCG Extralight';
    width: 100%;
    margin-left: 28px;
    margin-top: -10px;
}

.our-team__about-member p {
    font-size: 16px;
    font-family: 'Graphik LCG Extralight';
    width: 100%;
    margin-top: 0;
    margin-left: 28px;
    margin-bottom: 50px;
}

@media screen and (max-width: 1270px) {
    .our-team__text {
        left: 108px;
    }
    .our-team__button-right {
        right: 90px;
    }
    .our-team__button-left {
        right: 200px;
    }
}

@media screen and (max-width: 1150px) {
    .our-team__member {
        padding-left: 460px;
    }
}

@media screen and (max-width: 1060px) {
    .our-team__text {
        left: 148px;
    }
    .our-team__button-right {
        right: 170px;
    }
    .our-team__button-left {
        right: 280px;
    }
}

.is-not-hype {
    width: calc(100%);
    position: relative;
    height: 380px;
    background: #146957;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 0;
}

.is-not-hype span {
    background: #508d81;
    color: #fff;
}

.is-not-hype p {
    width: 1145px;
    font-family: 'Graphik LCG Light';
    font-size: 30px;
    line-height: 45px;
    color: #fff;
    filter: blur(10px);
}

.is-not-hype-text-selection {
    background: #FFFFFF;
    opacity: 0.2;
    width: 986px;
    height: 35px;
    position: absolute;
    margin-top: 48px;
}

.spiro-is-quality-container {
    width: 100vw;
    height: 680px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -20px;
}

.spiro-is-quality {
    width: 1205px;
    height: 270px;
    display: flex;
    justify-content: space-between;
}

.spiro-is-quality__video {
    width: 670px;
    height: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spiro-is-quality__video video {
    cursor: pointer;
}

.spiro-is-quality__video:focus, .spiro-is-quality__video video {
    outline: none;

}

.spiro-is-quality video {
    width: 570px;
    height: 270px;
    position: absolute;
}

.spiro-is-quality__video-control {
    width: 62px;
    height: 62px;
    border-radius: 62px;
    background: #fff;
    z-index: 100;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spiro-is-quality__text {
    width: 512px;
    height: 262px;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
}

.spiro-is-quality__text h5 svg {
    display: none;
}

.spiro-is-quality__text h5 {
    font-size: 30px;
    line-height: 45px;
    font-weight: normal;
    font-family: "Graphik LCG Light";
    margin-top: 10px;
    margin-bottom: 30px;
}

.spiro-is-quality__text p {
    font-size: 16px;
    line-height: 25px;
    font-family: "Graphik LCG Light";
    margin-top: -15px;
}

@media screen and (max-width: 1160px) {
    .spiro-is-quality__text {
        position: relative;
        left: 70px;
    }
}

.questions-and-answers {
    width: calc(100%);
    position: relative;
    min-height: 625px;
    background: #EBEBEB;
    display: flex;
    justify-content: center;
    margin-bottom: -20px;
}

.questions-and-answers__header {
    font-size: 30px;
    font-weight: normal;
    font-family: 'Graphik LCG Light';
    margin-top: 100px;
    position: absolute;
}

.questions-and-answers main,
.styles_faq-row-wrapper__3Hsch {
    width: 60%;
    margin-top: 160px;
    margin-bottom: 100px;
    background: transparent !important;
}

.styles_row-body__8wIE9 section > div {
    height: 56px;
    padding: 0 !important;
}

.styles_row-body__8wIE9 section > div div {
    font-size: 16px;
    font-family: 'Graphik LCG Light';
    font-weight: normal;
}

.styles_row-body__8wIE9 > section {
    border-bottom: 1px solid #dddddd !important;
}

.styles_faq-row__2Rd2Y h2 {
    display: none !important;
}

.styles_faq-row__2Rd2Y {
    padding: 0 !important;
    box-sizing: border-box !important;
    /*height: 56px !important;*/
}

.styles_faq-row__2Rd2Y {
    border-bottom: none !important;
}

.questions-and-answers__block {
    width: 100%;
    border-bottom: 1px solid #dddddd;
    margin-top: 0;
    min-height: 60px;
    cursor: pointer;
}

.questions-and-answers__block h6 {
    margin-bottom: 0;
    position: relative;
    top: 15px;

    /*bottom: 10px;*/
    font-size: 16px;
    font-family: 'Graphik LCG Light';
    font-weight: normal;
    margin-top: 0;
}

.questions-and-answers__block p,
.styles_row-content__TVd0Y {
    font-size: 16px;
    font-family: 'Graphik LCG Extralight';
    font-weight: normal;
    /*margin-bottom: 20px;*/
    /*margin-top: -10px;*/
    line-height: 20px;
    /*bottom: 10px;*/
    /*margin-top: 40px;*/
}

.questions-and-answers__block p {
    /*margin-bottom: 10px;*/
}

.styles_row-title__2yqnt {
    /*padding: 10px 0 0 0 !important;*/
    /*box-sizing: border-box !important;*/
    /*margin-top: 10px !important;*/
    position: relative;
    /*top: 10px;*/
    /*padding-top: 10px !important;*/
    /*margin-bottom: 10px;*/
    /*height: 66px !important;*/
}

.styles_row-title__2yqnt > div {
    padding-top: 10px;
}

.styles_row-content__TVd0Y {
    /*margin-bottom: 0 !important;*/
    position: relative;
    top: -10px;
    margin-bottom: 10px;
}

.questions-and-answers__block img {
    float: right;
    cursor: pointer;
    position: relative;
    transition: transform .5s;
}

.add-email {
    width: calc(100%);
    height: 337px;
    background: #1D1D1D;
    display: flex;
    justify-content: center;
    position: absolute;
    margin-top: -12px;
}

.add-email h5 {
    color: #fff;
    font-size: 30px;
    font-weight: normal;
    font-family: 'Graphik LCG Light';
    top: 85px;
    position: absolute;
    margin-top: 0;
    margin-bottom: 0;
}

.add-email p {
    font-size: 16px;
    font-family: 'Graphik LCG Light';
    color: #fff;
    position: absolute;
    top: 106px;
}

.add-email__form {
    width: 419px;
    height: 51px;
    position: absolute;
    bottom: 95px;
    border: 1px solid #fff;
    border-radius: 5px;
    top: 166px;
}

.add-email__input {
    background: transparent;
    height: 100%;
    font-size: 16px;
    width: 268px;
    color: #fff;
    font-family: 'Graphik LCG Light';
    border: none;
    padding-left: 17px;
    box-sizing: border-box;
}

.add-email__input::placeholder {
    color: #fff;
}

.add-email__button {
    float: right;
    width: 151px;
    height: calc(100%);
    background: #fff;
    color: #1d1d1d;
    font-size: 16px;
    border: none;
    transition: all .5s;
    cursor: pointer;
    border-left: 1px solid white;
    /*border-radius: 10px;*/
    border-bottom-right-radius: 3.5px;
    border-top-right-radius: 3.5px;
}

.admin-save {
    position: fixed;
    left: 10px;
    top: 10px;
    width: 160px;
    height: 42px;
    z-index: 99999999;
    background-color: #146957;
    font-family: "Graphik LCG Light";
    font-size: 21px;
    color: #fff;
    border-radius: 100px;
    opacity: 1 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.subscribe-form__beauty, .subscribe-form__performance {
    margin-top: 70px;
    opacity: .5;
}

.subscribe-form__active-superset {
    position: absolute;
    top: 20px;
    left: 125px;
    /*width: 175px;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.subscribe-form__active-superset span {
    /*width: 500px;*/
    display: block;
    font-size: 16px;
    font-family: "Graphik LCG Light";
    margin-left: 11px;
}

.subscribe-form__active-superset .subscribe-form__rectangle {
    /*display: none;*/
    border: 1px solid #1d1d1d;
    width: 23px;
    height: 23px;
    box-sizing: border-box;
}

.add-email__button:hover {
    background: #1D1D1D;
    color: #fff;
    transition: all .5s;
}

.hippocrates {
    width: calc(100%);
    position: relative;
    background: #EBEBEB;
    height: 384px;
    margin-top: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hippocrates__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
}

.hippocrates__image img {
    position: absolute;
}

.hippocrates__text {
    height: 100%;
    width: 382px;
    padding-top: 160px;
}

.hippocrates__text-admin h5 textarea {
    font-size: 22px;
    font-weight: normal;
    font-family: 'Graphik LCG Light';
    line-height: 33px;
    margin-top: 31.740px;
    width: 400px;
    height: 100px;
}

.hippocrates__text h5 {
    font-size: 22px;
    font-weight: normal;
    font-family: 'Graphik LCG Light';
    line-height: 33px;
    margin-top: 31.740px;
}

.hippocrates__text p {
    font-size: 16px;
    font-family: 'Graphik LCG Light';
}

.hippocrates__text-admin p textarea {
    font-size: 16px;
    font-family: 'Graphik LCG Light';
}

footer {
    width: 1300px;
    height: 210px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: nowrap;
    margin-left: auto;
    margin-right: auto;
}

.footer__block {
    width: 200px;
    height: 100px;
    display: flex;
    /*align-items: flex-start;*/
    justify-content: space-between;
    flex-direction: column;
}

.english-30 {margin-left: 30px; width: 100%}

.footer__block p,
.footer__block a {
    font-size: 15px;
    line-height: 20px;
    font-family: 'Graphik LCG Light';
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
}

.footer__block-socials a {
    width: 41px;
    height: 41px;
    display: inline-flex;
    background: #FFFFFF;
    box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}

.footer__block-socials a:hover:after,
.footer__block-socials a:focus:after
/*.make-with-love:focus:after*/
{
    width: 0;
}

.make-with-love {
    cursor: pointer;
    visibility: hidden;
}

.footer__block-socials p {
    margin-left: 15px;
    cursor: pointer;
    transition: color .5s;
}

.footer__block-socials p:hover {
    color: #146957 !important;
}

.footer__block-socials div {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: -10px;
}

.footer__navigation {
    align-items: flex-start;
    width: 70px;
}

.footer__up {
    width: 59px;
    height: 59px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 200px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: -webkit-filter .5s, filter .5s;
    bottom: -29.5px;
    margin-left: 620px;
    position: fixed;
    bottom: 100px;
    opacity: 0;
    transition: opacity 1s;
    margin-left: 0;
    right: 144px;
    z-index: 999999;
}

@-moz-document url-prefix() {
    .footer__up {
        margin-left: 1200px;
    }
}

.footer__up svg {

}

.footer__up:hover svg {
    animation-name: footer-up-animation;
    animation-duration: 2s;
}

@keyframes footer-up-animation {
    25%, 75% {
        transform: translate(0, -100%);
    }
    50%, 100% {
        transform: translate(0, 0);
    }
}

input[type=email]:focus
{
    border: none;
    outline: 0;
}

.facebook, .instagram, .youtube {
    transition: background-color .5s;
}

.facebook path, .instagram path, .youtube path {
    transition: fill .5s;
}

.facebook:hover {
    background: #117592;
}

.facebook:hover path {
    fill: #fff;
}

.instagram:hover {
    background: #CD3146;
}

.instagram:hover path {
    fill: #fff;
}

.youtube:hover {
    background: #CD3146;
}

.youtube:hover path {
    fill: #fff;
}

.personal-data-wrapper {
    width: 100vw;
    height: 100vh;
    display: none;
    background:rgba(0,0,0,.7);
    position: fixed;
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
}

.personal-data-wrapper svg {
    position: absolute;
    top: 0;
    left: calc(50% + 435px);
    cursor: pointer;
}

.personal-data {
    width: 800px;
    background: #fff;
    overflow: auto;
    height: calc(100vh - 100px);
    padding: 50px 30px;
    z-index: 10000;
}

.personal-data h1 {
    text-align: center;
    font-size: 30px;
    line-height: 45px;
    font-weight: normal;
    font-family: "Graphik LCG Light";
    /*margin-top: 0;*/
    /*margin-bottom: 0;*/
}

.personal-data p {
    font-size: 16px;
    line-height: 25px;
    font-family: "Graphik LCG Light";
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1024px) and (min-width: 769px) {
    .superset__description {
        right: 120px;
    }
    .superset__paragraph, .superset__title_svg, .superset__title {
        right: unset;
        left: 618px;
    }
    .phones__ellipse {
        margin-right: 10px;
    }
    .first-screen main {
        width: 100vw;
        padding-left: 20px;
    }
    .first-screen__title {
        width: 500px;
    }
    header {
        padding-left: 20px;
        padding-right: 20px;
    }
    .dish-with-dessert-static {
        opacity: 0;
        width: 536px;
        height: 525px;
    }
    .food-as-medicine__get-energy {
        left: 160px;
    }
    .food-as-medicine__better-digestion {
        left: 130px;
    }
    .food-as-medicine__strengthened-immunity {
        right: 160px;
        left: unset;
    }
    .food-as-medicine__normal-weight {
        right: 160px;
        left: unset;
    }
    .food-as-medicine__stamina {
        right: 160px;
        left: unset;
    }
    .food-menu-container {
        left: 0;
    }
    .superset {
        width: 1210px;
    }
    .subscribe {
        width: 100vw;
    }
    /*.subscribe-form__subscribe-button {*/
    /*    top: -10px;*/
    /*    height: 45px;*/
    /*}*/
    .phones {
        width: 100vw;
    }
    #iphone-model {
        margin-left: -60px;
    }
    .phones__ellipse {
        width: 404px;
        height: 404px;
    }
    .phones__text-about-app {
        left: 250px;
    }
    .spiro-is-quality__video, .spiro-is-quality__video video {
        width: 470px;
    }
    .spiro-is-quality__video {
        margin-right: 20px;
        margin-top: -15px;
    }
    .spiro-is-quality__text {
        left: 40px;
        width: 450px;
    }
}

@media screen and (max-width: 768px) {
    .our-philosophy {
        width: 500px;
    }
}

@media screen and (min-width: 769px) {
    .subscribe-form__title {width: 109px}
}


@media screen and (max-width: 992px) {
    .food-as-medicine__mobile-text img {
        width: 21px;
        height: 21px;
    }
    .footer__block p {
        text-align: center;
    }
    .make-with-love {
        display: none;
    }
    .site-navigation-after-scroll__burger-close {
        position: absolute;
        top: 20px;
        left: 20px;
    }
    .loading {
        width: calc(100vw);
        position: relative;
        /*left: -20px;*/
        top: -20px;
        height: calc(100vh + 20px);
    }
    .loading__circle {
        width: 25px;
        height: 25px;
        border-radius: 25px;
    }
    @keyframes circle-animation {
        0% {
            width: 25px;
            height: 25px;
            border-radius: 25px;
        }
        50% {
            width: 50px;
            height: 50px;
            border-radius: 50px;
        }
        100% {
            width: 25px;
            height: 25px;
            border-radius: 25px;
        }
    }
    .first-screen {
        position: relative;
        /*left: -7px;*/
        display: block;
    }
    .first-screen main {
        width: auto;
        height: 567px;
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 32px;
        box-sizing:border-box;
        position: static;
        display: block;
    }
    .first-screen__title {
        margin-top: 0;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        font-size: 22px;
        line-height: 31px;
        /*height: 96px;*/
        width: 290px;
        height: unset;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .first-screen__text {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /*margin-top: -55px;*/
        font-size: 14px;
        line-height: 20px;
        width: 290px;
        height: 78px;
        /*height: 78px;*/
    }
    .watch-video {
        margin-left: auto;
        margin-top: 40px;
        margin-right: auto;
    }
    .watch-video span {
        position: relative;
        left: -5px;
    }
    #dish {
        width: 100%;
        height: 320px;
        position: static;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -30px;
    }
    .dish__flying-dish {
        width: 320px;
        height: 320px;
        /*display: block;*/
        /*opacity: 1;*/
    }
    .dish__dessert {
        width: 160px;
        height: 160px;

    }
    .dish__table-dish {
        width: 320px;
        height: 320px;
        /*display: block;*/
        /*opacity: 1;*/
        margin-top: 10px;
    }
    .dish-with-dessert-static {
        width: 320px;
        height: 320px;
        opacity: 0;
        position: relative;
        top: -15px;
    }
    #spiro {
        display: none;
    }
    .blue-ellipse {
        width: 1313px;
        height: 1313px;
        border-radius: 1313px;
        position: absolute;
        top: unset;
        /*margin-top: -150vh;*/
    }

    .food-as-medicine {
        height: 1313px;
        margin-top: calc(-100vh + 465px);
    }

    .subscribe-form__main-block {

    }

    .white-ellipse {
        width: 790px;
        height: 790px;
    }
    #shadow-dish {
        display: none;
    }
    .food-as-medicine__main-text {
        width: 100vw;
        height: 54px;
        font-size: 21px;
        line-height: 25px;
        /*margin-top: 50px;*/
        margin: 0;
        top: 69px;
    }
    .food-as-medicine__main-text-eng {
        top: 100px
    }
    .food-as-medicine__text-under-main-text {
        font-size: 14px;
        line-height: 20px;
        width: 290px;
        height: 81px;
        margin: 0;
        top: 138px;
    }
    .personal-data {
        width: 62vw;
        /*right: 0;*/
        /*left: unset;*/
    }
    .personal-data-wrapper svg {
        left: unset;
        right: 0;
    }
    .personal-data h1 {
        font-size: 21px;
        line-height: 20px;
    }
    .personal-data p {
        font-size: 14px;
    }
    .food-as-medicine_little-text {
        background: transparent;
        position: static;
        justify-content: flex-start;
        /*display: flex;*/
        /*flex-direction: column;*/
        /*justify-content: space-around;*/
        width: 100%;
        font-size: 14px;
    }
    .food-as-medicine_little-text img {
        margin-right: 20px;
    }
    .our-philosophy {
        height: 243px;
        position: relative;
        /*top: -80px;*/
        margin-left: 15px;
        margin-right: 15px;
        box-sizing: border-box;
        bottom: 0;
        top: 85px;
    }
    .our-philosophy__background {
        height: 243px;
        width: 100%;
        top: 0;
    }
    .our-philosophy__background img:first-child {
        width: 27px;
        height: 23px;
        margin-bottom: 18px;
    }
    .our-philosophy__spiro-photo {
        width: 199px;
        height: 154px;
        position: relative;
        left: 0;
        top: 85px;
    }
    .our-philosophy__background img {
        margin-top: -10px;
        margin-left: 20px;
    }
    .instagram-image {
        display: none!important;
    }
    .our-philosophy__background p {
        width: 90%;
        height: 72px;
        font-size: 14px;
        margin-left: 20px;
        line-height: 20px;
        margin-top: 0;
        margin-bottom: 0;
    }
    .superset__title_svg {
        width: 96px;
    }
    .our-philosophy__background span {
        font-size: 14px;
        line-height: 20px;
        margin-left: 20px;
    }
    .how-it-works-container {
        height: 705px;
        width: calc(100%);
        margin-top: 830px;
        /*margin-top: -260px;*/
        /*left: 10px;*/
        /*flex-direction: column;*/
        /*justify-content: flex-start;*/
    }
    .how-it-works {
        width: 100%;
        flex-direction: column;
        height: 566px;
        align-items: center;
        top: 18px;
        /*top: -60px;*/
    }
    .how-it-works p {
        width: 280px;
    }
    body {
        margin-left: 0;
        margin-right: 0;
    }
    .how-it-works__info-food {
        width: 280px !important;
        top: 104px;
    }
    .how-it-works__info-circle {
        width: 65px;
        height: 65px;
        margin-top: 10px;
    }
    .how-it-works-schedule {
        width: 31px;
        height: 26px;
        margin-left: 4px;
    }
    .how-it-works__leaf {
        width: 28px;
        height: 33px;
    }
    .how-it-works__fork {
        width: 5px;
        height: 20px;
        margin-right: 3.39px;
    }
    .how-it-works__dish {
        width: 23px;
        height: 21px;
        margin-right: 2.58px;
    }
    .how-it-works__spoon {
        width: 7px;
        height: 22px;
    }
    .how-it-works-text {
        top: 50px;
        font-size: 21px;
        text-align: center;
    }
    .subscribe-form__beauty, .subscribe-form__performance {
        margin-top: 54px !important;
        width: 72px !important;
    }
    .subscribe-form__label.subscribe-form__beauty, .subscribe-form__label.subscribe-form__performance {
        opacity: .5;
    }
    .subscribe-form__performance {
        margin-left: 9% !important;
        width: 110px !important;

    }
    .subscribe-form__every-week {
        margin-top: 45px !important;
    }
    .how-it-works__info {
        position: relative;
        height: 178px;
    }
    .how-it-works__info:last-child {
        padding-top: 30px;
    }
    .how-it-works p {
        font-size: 14px;
        line-height: 20px;
        margin-top: 12px;
    }
    .spiro-is-quality__text p {
        margin-top: 15px !important;
        margin-bottom: 0;
        font-family: "Graphik LCG Light" ;
        /*width: 230px !important;*/
    }
    .reviewer__spiro-symbol {
        /*margin-left: 13px;*/
    }
    .how-it-works__info-schelude {
        margin-top: 0px !important;
        /*width: calc(100vw - 40px) !important;*/
        top: 104px;
    }
    .how-it-works__info-superfood {
        /*width: calc(100vw - 40px) !important;*/
        top: 104px;
    }
    .food-menu__dish {
        position: static;
        width: 264px !important;
        height: 331px !important;;
        box-shadow: 3.16178px 3.16178px 9.48534px rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;
        margin-top: 10px;

    }
    .footer__up {
        display: none!important;
    }
    .subscribe-form__delivery-time {
        display: flex;
        justify-content: space-between;
        width: calc(100%)
    }
    .subscribe-form__delivery-time .Dropdown-arrow {
        right: 10px !important;
    }
    .subscribe-form__monday {
        margin-left: 0;
        width: 48%;
        height: 50px;
        /*display: none;*/
    }
    .subscribe-form__monday .Dropdown-arrow, .subscribe-form__wednesday .Dropdown-arrow {
        top: 23px!important;
        right: 22px!important;
    }
    .subscribe-form__monday .Dropdown-control {
        height: 50px!important;
        /*display: none;*/
    }
    .subscribe-form__wednesday {
        width: 48%;
        height: 50px;
        margin-right: 0!important;
        margin-left: 0!important;
    }
    .subscribe-form__wednesday .Dropdown-control {
        height: 50px!important;
    }
    .food-menu__dish * {
        transition: opacity .25s;
    }
    .food-menu__dish img {
        width: 264px;
        height: 264px;
    }
    .superset-container {
        width: 100vw;
        height: 827px;
        margin-top: 30px;
    }
    .superset {
        width: 100%;
        height: 827px;
    }
    .superset__wall {
        display: none;
    }
    .superset__text {
        top: -30px;
    }
    .superset__text, .superset__star {
        width: 166px;
        height: 166px;
    }
    .superset__text img:last-child {
        width: 78px;
        height: 36px;
    }
    .superset__title {
        left: 15px;
        font-size: 21px;
        top: 40px;
    }
    .superset__title_svg {
        left: 15px;
        top: 60px;
        width: 111px;
    }
    .superset__title-eng-svg {
        width: 96px;
    }
    .superset__element-description {
        line-height: 15px;
        margin-left: 15px;
        margin-right: 15px;
        font-size: 14px;
    }
    .superset__paragraph {
        left: 15px;
        top: 77px;
        font-size: 14px;
        width: calc(100vw - 23px);
        font-size: 14px;
        line-height: 20px;
    }
    .superset__description {
        left: 11px;
        width: calc(100vw - 22px);
        height: 375px;
        padding-top: 25px;
        top: 166px;
    }
    .superset__beauty-button {
        width: 99px;
        height: 31px;
        font-size: 14px;
        padding-top: 9px;
    }
    .superset__performance-button {
        width: 148px;
        height: 31px;
        /*right: 28px;*/
        /*left: unset;*/
        position: absolute;
        font-size: 14px;
        padding-top: 9px;
    }
    .superset__description-title {
        width: calc(100% - 11px);
        font-size: 14px;
        line-height: 20px;
        margin-top: 15px;
    }
    .superset__description-text {
        width: calc(100% - 31px);
        font-size: 14px;
    }
    .superset__buttons {
        display: none;
    }
    .superset__description-text li {
        margin-bottom: 15px;
    }
    .superset__description-text span {
        width: 0;
        /*padding-left: 50px;*/
        /*margin-left: -2px;*/
    }
    .superset__description-text li {
        margin-bottom: 10px;
        list-style: unset;
        margin-left: 17px;
        line-height: 20px;
    }
    .superset__description-text li:before {
        content:"·";
        font-size:120px;
        /*vertical-align:middle;*/
        position: absolute;
        line-height:20px;
        margin-left: -25px;
        margin-top: 0px;
    }
    .superset-mobile__elements {
        height: 334px;
        z-index: 9999;
        position: absolute !important;
        bottom: 58px;
        z-index: 9999999;
    }
    .superset-mobile__elements .slick-list {
        height: calc(100% + 20px);
        width: 548px;
    }
    .superset__image {
        width: 264px;
        height: 264px;
    }
    .superset__element {
        width: 264px;
        height: 334px;
        position: static;
    }
    .superset-mobile__elements {
        width: 568px !important;
        padding-left: 11px;
    }
    .subscribe-form__your-comments {
        width: calc(100%);
        margin-left: 0;
        margin-top: 15px;
        height: 101px;
        padding-left: 13px!important;
        font-size: 14px!important;
        line-height: 20px;
    }
    .subscribe-form__5-days, .subscribe-form__3-days {
        margin-top: 45px !important;
    }
    .subscribe-form__your-comments::placeholder {
        font-size: 14px;
    }
    .subscribe-form__active-superset {
        left: 0;
        /*width: 150px;*/
        top: 280px;
    }
    .subscribe-form__active-superset span {
        font-size: 14px;

    }
    .subscribe-form__active-superset .subscribe-form__rectangle {
        width: 19px;
        height: 19px;
    }
    .subscribe-form__thanks-for-subscribe {
        width: 100vw;
        font-size: 16px;
    }
    .subscribe-form__thanks-for-subscribe h1 {
        font-weight: 14px;
    }
    .subscribe-form__thanks-for-subscribe button {
        width: calc(100vw - 20px);
        font-weight: 12px;
    }
    .subscribe-form__thanks-for-subscribe p {
        width: calc(100vw - 20px)
    }
    .subscribe-form-container {
        background-image: url("../public/subscribeForm/subscribeBackgroundMobile.jpg") !important;
        /*background-image: url(../public/subscribeForm/background-man.jpg), url(../public/subscribeForm/form-bg_blue.jpg);*/
        background-size: cover;
        /*background-image: none;*/
        padding: 0;
        height: 1066px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100vw + 20px);
        position: relative;
        left: -10px;
        justify-content: center;
        /*margin-top: 30px;*/
    }
    .subscribe * {
        opacity: 1;
    }
    .subscribe {
        width: calc(100%);
        top: 80px;
        display: flex;
        padding-left: 10px;
        height: 936px;
    }
    .subscribe-form {
        width: calc(100vw - 30px);
        height: 650px!important;
        margin: 0;
        /*margin-left: auto;*/
        /*margin-right: auto;*/
        padding: 0;
        margin-top: -20px;
        margin-left: 15px;
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
        padding-left: 15px;
        box-sizing: border-box;
    }
    .subscribe-form__line {
        width: calc(100% - 40px) !important;
        /*padding-top: 25px;*/
        /*!*box-sizing: border-box;*!*/
        /*height: 67px !important;*/
    }
    .subscribe-form__main-block {
        width: 733px;
        top: 0;
    }
    .subscribe-form__header {
        font-size: 21px;
        margin-top: 26px;
        margin-bottom: 17px;
        width: 100%;
    }
    .subscribe-form__title {
        position: absolute;
        margin: 0;
        top: 0;
        width: calc(100vw - 20px) !important;
        font-size: 14px;
        /*margin-top: -25px;*/
    }
    .subscribe-form__circle {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .subscribe-form__label {
    }
    .subscribe-form__label span {
        font-size: 14px;
    }
    .subscribe-form__title br {
        display: none;
    }
    .subscribe-form__main-block-second {
        width: 100%;
        height: 758px;
    }
    .subscribe-form__main-block-second .subscribe-form__line {
        width: calc(100% - 20px) !important ;
    }
    .subscribe-form__main-block-second .subscribe-form__subscribe-button {
        margin-top: 50px;
        width: calc(100% - 18px);
        box-sizing: border-box;
        margin-left: 1px;
        top: -15px
    }
    .subscribe-form__main-block-second .subscribe-form__header {
        margin-bottom: 20px;
        font-size: 19px;
    }
    .subscribe-form__main-block-second input {
        font-size: 14px;
    }
    .subscribe-form__phone-number-block {
        height: 80px !important;
    }
    .subscribe-form__man {
        /*margin-left: -30px !important;*/
        /*margin-top: 8px;*/
        margin-left: 0 !important;
    }
    .subscribe-form__woman {
        margin-left: 7vw !important;
        /*margin-top: 8px;*/
    }
    .subscribe-form__not-all-fields {
        display: none;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 55px);
        height: 107px;
        bottom: 120px;
        /*display: flex;*/
        justify-content: center;
        align-items: center;
        opacity: 0;
    }
    .subscribe-form__not-all-fields-cloud {
        position: relative;
        width: 100%;
        height: 100px;
        background: #FFFFFF;
        border: 1px solid #CD3146;
        box-sizing: border-box;
        box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        /*display: none;*/
        /*left: 10px;*/
    }
    .subscribe-form__not-all-fields-cloud:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        left: calc(50% - 18.9px);
        top: 87px;
        border: 11px solid;
        border-color: #CD3146 transparent transparent #CD3146;
        transform: rotate(225deg);
    }
    .subscribe-form__not-all-fields-cloud:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: calc(50% - 18px);
        top: 87.5px;
        border: 10px solid;
        border-color: #fff transparent transparent #fff;
        transform: rotate(225deg);
    }
    .subscribe-form__not-all-fields h5 {
        font-size: 14px;
        left: 20px;
    }
    .subscribe-form__not-all-fields p {
        font-size: 14px;
        width: calc(100% - 20px);
        left: 20px;
        top: 39px;
    }
    .subscribe-form__your-name {
        width: calc(100%);
        margin-left: 0;
        height: 50px;
        margin-top: 10px;
        padding-left: 10px;
    }
    .subscribe-form__phone-number {
        width: calc(100%);
        margin-left: 0;
        height: 50px;
        padding-left: 10px;
        /*margin-top: 30px;*/
        margin-top: -20px;
        /*padding-bottom: 12px;*/
    }
    .subscribe-form__address {
        margin-left: 0;
        /*grid-column-gap: 7%;*/
        grid-row-gap: 10px;
        margin-top: -23px;
        width: calc(100% - 10px);
        grid-template-columns: 50% 50%;
        /*grid-column-gap: 0;*/
        /*display: grid;*/
        /*grid-template-columns: 147px 68.52px 68.52px;*/
        grid-column-gap: 10px!important;
        grid-template-rows: 50px 50px 50px;
        /*grid-row-gap: 20px;*/
    }
    .subscribe-form__address .Dropdown-control {
        height: 50px;
    }
    .subscribe-form__town {
        width: calc(100% - 10px);
        height: 50px;
    }
    .subscribe-form__town .Dropdown-arrow {
        top: 23px;
        right: 22px;
    }
    .subscribe-form__delivery-time {
        margin-top: 50px;
    }
    .spiro-blick {
        width: 200px;
        display: none;
        height: 200vh;
        left: -100px;
    }
    .subscribe-form__delivery-time .Dropdown-arrow {
        top: 15px;
        right: 15px;
    }
    .subscribe-form__delivery-time .Dropdown-control {
        height: 35px;
    }
    /*.subscribe-form__main-block-second {*/
    /*    display: none;*/
    /*}*/
    .subscribe-form__main-block-second .subscribe-form__title {
        display: none;
    }
    .subscribe-form__line-wednesday {
        width: 43px;
        opacity: .5;
        top: 0;
        position: static;
        font-size: 14px;
    }
    .subscribe-form__monday-wednesday {
        position: absolute;
        top: 505px;
        width: calc(100% - 10px);
        height: 20px;
        left: unset;
        display: flex;
        justify-content: space-between;
    }
    .subscribe-form__line-monday {
        opacity: .5;
        position: static;
        font-size: 14px;
    }
    .food-menu__second-image img {
        margin-top: 9px;
    }
    .subscribe-form__town .Dropdown-control {
        padding-left: 10px;
    }
    .subscribe-form__town .Dropdown-placeholder {
        font-size: 14px;
    }
    .subscribe-form__street {
        height: 50px;
        padding-left: 10px;
        grid-column: 1 / 4;
        width: calc(100% - 10px)
    }
    .subscribe-form__home {
        height: 50px;
        padding-left: 10px;
        width: 100%;
    }
    .subscribe-form__flat {
        height: 50px;
        padding-left: 10px;
        width: 100%;
    }
    .subscribe-form__subscribe-button {
        width: calc(100% - 40px);
        height: 50px;
        font-size: 14px;
        margin-left: 0px;
    }
    .subscribe-form__rectangle {
        width: 19px;
        height: 19px;
    }
    .subscribe-form__set-date {
        width: 100% !important;
        margin-top: 70px;
        margin-left: 0px;
    }
    .subscribe-form__about-delivery {
        /*margin-left: auto;*/
        /*margin-right: auto;*/
        margin-left: 0;
        /*padding-right: 38px;*/
        width: calc(100vw - 60px);
        font-size: 14px;
        margin-top: 15px;
        line-height: 20px;
        margin-bottom: 55px;

    }
    .subscribe-form__main-block-second .subscribe-form__about-delivery {
        margin-top: 20px;
    }
    .subscribe-form__set-date .Dropdown-control {
        width: calc(100%);
        height: 35px;
        padding-left: 10px;
        border-radius: 5px !important;
    }
    .subscribe-form__set-date {
        width: calc(100% - 20px);
        height: 50px;
        margin-top: 35px;
    }
    .subscribe-form__set-date .Dropdown-placeholder {
        font-size: 14px;
    }
    .subscribe-form__set-date .Dropdown-arrow {
        top: 16px !important;
        right: 14px !important;
    }
    .subscribe-form__set-date .Dropdown-option {
        font-size: 14px;
    }
    .health-info__header {
        width: calc(290px);
        height: 76px;
        line-height: 25px;
        margin: 0;
        margin-bottom: 50px;
        font-size: 21px;
        margin-top: -50px;
        position: relative;
        top: 30px;
    }
    .health-info__header span {
        background: #EBEBEB;
    }
    .health-info__header p {
        display: inline;
    }
    .health-info__header p svg {
        position: absolute;
        margin-left: -185px;
        margin-top: 20px;
    }
    .reviews-dot {
        margin-top: -40px;
    }
    .reviews-header {
        font-size: 21px;
        text-align: center;
        margin-top: 60px;
        font-family: "Graphik LCG Light";
    }
    .reviews-container {
        height: 514px;
        margin-top: -80px;
    }
    .reviewer {
        height: 328px;
        width: calc(100% - 12px) !important;
        margin-left: -3px;
    }
    .reviews__carousel .slick-slide {
        /*margin-left: -10px !important;*/
    }
    .reviews__carousel .slick-dots {
        margin-left: -5px;
    }
    .reviews__carousel .reviews-dot {
        margin-top: -40px !important;
    }
    .reviewer__image {
        width: calc(100%);
        height: 192px;
        object-fit: cover;
        border-radius: 5px;
    }
    .reviewer__name {
        position: static;
        margin-bottom: 0;
        margin-top: 15px;
        font-family: "Graphik LCG Regular";
    }

    .reviewer__text {
        font-family: "Graphik LCG Light";
        margin-top: 10px;
        position: static;
        width: 100%;
        height: 79px;
        font-size: 14px;
        line-height: 20px
    }
    .reviews__carousel {
        height:328px;
        width: calc(100% - 20px);
        margin-top: -40px;
        justify-content: center;
    }
    .phones-container {
        height: 4096px !important;
        box-sizing: border-box;
        margin-top: -1px;
    }
    .phones {
        width: 100vw;
        height: 610px;
        position: relative;
        background-color: #EBEBEB;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .phones__zero-phone {
        z-index: 2000;
        position: absolute;
        top: 57px;
        left: 5px;
        width: 69.06vw;
    }
    .phones h6 {
        font-size: 21px !important;
    }
    .phones__ellipse {
        width: 233px;
    }
    .phones__ellipse img {
        width: 213px !important;
        height: 390px !important;
    }
    .phones-mobile__text h6 {
        background: #fff;
        display: inline;
        font-family: "Graphik LCG Light" !important;
    }
    .phones-mobile__text p {
        font-family: "Graphik LCG Regular" !important;
    }
    .phones-mobile__text span {
        font-family: "Graphik LCG Light" !important;
        /*width: calc(200px) !important;*/
    }
    .phones__opacity {
        position: absolute;
        bottom: 225px;
        right: 10px;
        width: 136px;
        height: 306px;
    }
    .phones__not-about-food span {
        background: #fff;
        color: #1d1d1d;
    }
    .phones__third-svg, .phones__first-svg, .phones__second-svg {
        position: absolute;
        z-index: 99999;
        display: none;
    }
    .phones__first-svg {
        top: 1435px;
        left: 20px;
    }
    .phones__second-svg {
        top: 2110px;
        left: 20px;
    }
    .phones__second-svg path {
    }
    .phones__third-svg {
        top: 3330px;
        left: 20px;
    }
    .phones__we-crafted svg {
        position: relative;
        top: -18px;
        display: none;
    }

    .phones__second {
        margin-top: -90px
    }
    .phones__third {
        margin-top: 65px;
    }
    .phones__second img, .phones__fourth img, .phones__sixth img {
        width: 237px;
    }
    .phones__fourth {
        margin-top: 0px;
    }
    .phones__fifth {
        margin-top: 0px;
    }
    .phones__sixth {
        margin-top: 30px;
        margin-bottom: 100px;
    }
    .phones__night-image img {
        width: 100%;
        /*max-height: px;*/
        /*height: 427px;*/
        left: 0;
        z-index: 1000;
    }
    .phones__night-image {
        width: 100%;
        height: unset;
        max-width: unset;
        min-width: unset;
        position: static;
        /*height: 200vh;*/
    }
    .phones__night-image > img {
        min-width: unset;
    }
    .phones__first {
        /*height: 200vh;*/
        height: unset;
    }
    #iphone-model {
        width: 0;
        height: 0;
    }
    .phones-mobile__text {
        margin-top: 78px !important;
        width: calc(100vw - 60px) !important;
    }
    .phones-mobile__text h6 {
        font-size: 19px;
    }
    .phones__text-about-app {
        position: relative;
        top: 32px;
        width: 284px;
        /*height: 293px;*/
        height: unset;
        left: 15px !important;
        z-index: 100000;
        color: #1d1d1d !important;
    }
    .phones__not-about-food {
        font-size: 21px;
        /*width: 284px;*/
        top: 0;
        left: 10px;
        color: #1d1d1d !important;
        margin-top: 0;
        line-height: 29px;
        width: 100%;
        position: static;
        margin-bottom: 0px;
        height: unset;
    }
    .phones__we-crafted {
        line-height: 29px;
        width: 290px !important;
        top: -10px;
        left: 10px;
        font-family: "Graphik LCG Light";
        color: #1d1d1d !important;
        position: static;
        margin-bottom: 0px;
        height: unset;
        margin-top: 15px;
    }
    ul.phones__we-crafted {
        top: 54px;
        left: 5px;
        height: auto;
        color: #1d1d1d !important;
        background: #fff;
        padding-left: 10px;
        width: calc(100vw - 50px) !important;
        padding-top: 10px;
        border-radius: 10px;
        font-size: 14px;
        list-style: none;
        padding-bottom: 10px;
        position: static;
    }
    ul.phones__we-crafted li:before {
        content:"·";
        font-size:80px;
        vertical-align:middle;
        line-height:20px;
        position: absolute;
    }
    ul.phones__we-crafted span {
        color: #1d1d1d!important;
        display: block;
        margin-left: 20px;
        position: relative;
        top: -3px;
    }
    p.phones__we-crafted {
        font-family: "Graphik LCG Light";
        margin-bottom: 20px;
        margin-top: 30px;
        font-size: 21px;
        width: calc(100vw - 61px) !important;
    }
    .phones__we-crafted li {
        margin-bottom: -1px;
        margin-left: 0 !important;
    }
    .phones__ellipse {
        width: 300px;
        height: 300px;
        border-radius: 300px;
        background: #f5f5f5;
        opacity: 1;
        position: static;
        margin-left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 107px;
    }
    .phones__ellipse img {
        z-index: 100;
    }
    .phones img {
        /*position: absolute;*/
    }
    .phones-container {
        border-bottom: 0;
        height: unset!important;
    }
    .is-not-hype {
        margin-top: 230px;
        height: 303px;
        box-sizing: border-box;
        padding-left: 15px;
        padding-right: 15px;
        width: 100vw;
        left: 0;
    }
    .is-not-hype p {
        font-size: 21px;
        filter: blur(0);
        width: 320px;
        margin-left: auto;
        margin-right: auto;
        line-height: 29px;
    }
    .spiro-is-quality-container {
        width: calc(100vw + 20px);
        height: unset;
        position: relative;
        left: -10px;
        align-items: flex-start;
    }
    .spiro-is-quality {
        flex-direction: column;
        height: unset;
        width: 100vw;
        padding-left: 25px;
    }
    .spiro-is-quality__text {
        width: 290px;
        height: unset;
        position: static;
        margin-top: 50px;
    }
    .spiro-is-quality__text h5 svg {
        position: absolute;
        display: block;
        width: 220px !important;
        margin-top: -60px;
        display: none;
    }
    .spiro-is-quality__text h5 {
        font-size: 21px;
        line-height: 29px;
        margin-bottom: 0;
    }
    .spiro-is-quality p {
        font-size: 14px;
        line-height: 20px;
        /*margin-bottom: 80px;*/
        width: calc(100vw - 45px);
        font-family: "Graphik LCG Light";
    }
    .spiro-is-quality__video video,
    .spiro-is-quality__video
    {
        height: 154px;
        border-radius: 5px;
        object-fit: cover;

    }
    .spiro-is-quality__video {
        padding-right: 35px;
        margin-top: 30px;
        margin-bottom: 20px;
        /*margin-left: 15px;*/
        /*margin-right: 15px;*/
        width: calc(100vw - 30px);
    }
    .spiro-is-quality__video video {
        max-width: unset !important;
        max-height: unset !important;
        width: calc(100vw - 30px);
        left: 25px;
    }
    .spiro-is-quality__video-control {
        width: 35px;
        height: 35px;
        /*position: relative;*/
        /*left: 10px;*/
    }
    .spiro-is-quality__video-control img {
        width: 10px;
        height: 12px;
        position: relative;
        left: 1px;
    }
    .questions-and-answers__header {
        width: 250px;
        height: 26px;
        font-size: 21px;
        z-index: 1000;
        /*position: absolute;*/
        /*top: -50px;*/
        /*left: 0;*/
        margin-top: 56px;
        text-align: center;
    }
    .add-email {
        height: 277px;
        margin-top: 0
    }
    .add-email h5 {
        font-size: 21px;
        margin-top: -30px;
    }
    .add-email p {
        font-size: 14px;
        line-height: 20px;
        width: 290px;
        height: 40px;
        text-align: center;
        margin-top: -20px;
    }
    .add-email__form {
        width: 290px;
        height: 48px;
        margin-top: -10px;
        border-radius: 5px !important;
    }
    .superset__wall-right {
        display: none;
    }
    .add-email__button {
        width: 102px;
        font-size: 14px;
        padding-left: 0;
        padding-right: 0;
        border-radius: 0;
        font-family: "Graphik LCG Light" !important;
    }
    .add-email__input {
        width: 186px;
        box-sizing: border-box;
    }
    .hippocrates {
        flex-direction: column-reverse;
        height: 447px;
        justify-content: space-between;
        margin-top: 260px;
        padding-top: 50px;
    }
    .hippocrates__text {
        width: 290px;
        height: 72px;
        margin-bottom: 100px;
        padding-top: 0;
        top: -50px;
        position: relative;
    }
    .hippocrates__text img {
        margin-left: -10px;
    }
    .hippocrates__text h5 {
        font-size: 15px;
        line-height: 20px;
        text-align: center;
    }
    .hippocrates__text p {
        text-align: center;
        font-size: 14px;
        line-height: 20px;
        margin-top: -15px;
    }
    .hippocrates__image,
    .hippocrates__image img{
        width: 233px;
        height: 233px;
    }
    .hippocrates__image img:last-child {
        width: 177px;
        height: 212px;
    }
    .hippocrates__image {
        position: relative;
        bottom: 40px;
        margin-top: 100px;
    }
    footer {
        flex-direction: column;
        height: 453px;
        width: calc(100vw);
        position: relative;
        margin-left: 0;
        margin-right: 0;
        padding: 30px 0 20px;
    }
    #logo {
        position: relative;
        left: -6px;
    }
    .how-it-works__info {
        padding-top: 15px!important;
    }
    .how-it-works__info p {
        margin-top: 20px;
    }
    .food-menu__dish-description {
        margin-left: 15px;
        margin-right: 15px;
        font-size: 14px;
        height: 39px;
        line-height: 20px;
    }
    .food-menu__dish-description span {
        /*position: relative;*/
        /*top: -7px;*/
    }
    .slick-dots-our-team .dot-our-team  {
        margin-left: -145px;
        margin-top: -45px;
        width: 30px;
        /*background-color: red;*/
    }
    .slick-dots-our-team li {
        margin-right: 10px!important;
    }
    .our-team__background {
        width: 90%;
        height: 443px;
        position: absolute;
        top: -70px;
        /*left: 48px;*/
        left: 48px;
        border-radius: 5px;
    }
    .footer__navigation {
        align-items: center;
    }
    .footer__block-socials p {
        text-align: center;
        margin-left: 0;
    }
    .footer__block-socials {
        align-items: center;
    }
    .first-screen__static-dish {
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 400px;
        display: none;
        height: 265px;
    }
    .first-screen__static-dish img {
        max-width: 337px;
        max-height: 265px;
        position: static;
    }
    #app {
        overflow-x: hidden;
        position: relative;
    }
    .first-screen__static-dish svg {
        display: none;
    }
    .subscribe-form__checked-mark {
        top: 0;
        left: 0;
        width: 10px !important;
        height: 6.5px !important;
        opacity: 0;
    }
}

.header-mobile, .header-mobile_fixed {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100vw );
    position: relative;
    padding: 20px 0;
    padding-left: 14px;
    height: 55px;
    background: #fff;
    top: 0;
    z-index: 999999;
}

.header__burger {
    position: absolute;
    left: 20px;
    z-index: 9999999;
    opacity: 1;
}

.header-mobile_fixed {
    position: fixed;
    top: 0;
    opacity: 0;
    display: none;
    background:rgba(255,255,255);
}

.food-as-medicine__mobile-text {
    width: 250px;
    height: 266px;
    position: absolute;
    /*position: relative;*/
    top: 556px;
    /*border: 1px solid black;*/
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    opacity: 0;
    z-index: 1000;
    /*margin-left: 36px;*/
    padding-left: 11px;
}

.food-as-medicine__mobile-text img {
    margin: 0;
    margin-right: 12px;
}

.food-menu-mobile-container {
    width: calc(100vw);
    position: relative;
    /*left: -10px;*/
    height: 590px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -680px;
}

.food-menu-mobile {
    height: 591px;
    width: calc(100%);
    position: absolute;
    margin-top: 5px;
}

.food-menu-mobile__dishes .slick-list {
    padding-left: 4px;
    width: unset;
}

.food-menu-mobile__menu-text {
    align-self: center;
    position: absolute;
    top: 50px;
    left: 15px;
    /*top: 50px;*/
    /*left: -125px;*/
    font-size: 21px;
    line-height: 25px;
    font-family: 'Graphik LCG Light';
    font-weight: normal;
    margin: 0;
}

.food-menu-mobile__weeks {
    width: calc(100% - 21px);
    height: 47px;
    background: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    top: 87px;
    left: 10px;
    border-radius: 5px;
}

.food-menu-mobile__next-week,
.food-menu-mobile__current-week{
    text-align: center;
    display: flex;
    justify-content: center;
    font-family: 'Graphik LCG Light';
    align-items: center;
    height: 100%;
    width: 50%;
    position: absolute;
}

.food-menu-mobile__current-week {
    color: #146957;
    border-bottom: 2px solid #146957;
    font-weight: bold;
    left: 0;
    top: 0;
    margin-top: 0;
}

.food-menu-mobile__next-week {
    margin-top: 0;
    right: 0;
    top: 0;
    height: 100%!important;
}

.food-menu-mobile__form {
    width: calc(100% - 50px);
    height: 117px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 149px;
    left: 10px;
    display: none;
}

.food-menu-mobile__days {
    /*border: 1px solid black;*/
    /*width: 100%;*/
    height: 50px;
    width: calc(100vw - 20px);
    background: #fff;
    font-size: 14px;
    font-family: 'Graphik LCG Light';
    box-sizing: border-box;
}

.food-menu-mobile__days .Dropdown-arrow {
    color: #000 !important;
}

.food-menu-mobile__days .Dropdown-control,
.food-menu-mobile__styles .Dropdown-control {
    border: 1px solid black;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

}

.food-menu-mobile__days .Dropdown-placeholder,
.food-menu-mobile__styles .Dropdown-placeholder {
    font-size: 14px;
    font-family: 'Graphik LCG Light';
}

.food-menu-mobile__days .Dropdown-arrow,
.food-menu-mobile__styles .Dropdown-arrow {
    top: 20px;
    border-color: #1d1d1d transparent;
}

.food-menu-mobile__days.is-open .Dropdown-option,
.food-menu-mobile__styles.is-open .Dropdown-option {
    font-size: 14px !important;
    font-family: 'Graphik LCG Light' !important;
    height: 50px;
    display: flex;
    align-items: center;
}

.food-menu-mobile__days .Dropdown-option.is-selected,
.food-menu-mobile__styles .Dropdown-option.is-selected {
    background: #146957;
    color: #fff;
}

.food-menu-mobile__styles {
    background: #fff;
    /*width: 100%;*/
    width: calc(100vw - 20px);
    height: 50px;
    font-size: 14px;
    font-family: 'Graphik LCG Light';
    box-sizing: border-box;
}

.food-menu-mobile__mark {
    align-self: flex-end;
    position: relative;
    right: 10px;
    top: 30px;
}

.food-menu-mobile__times {
    width: 400px;
    height: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 156px;
    left: 10px;
}

.food-menu-mobile__times .slick-list {
    /*width: 100vw;*/
    width: 380px;
}

@media screen and (width: 768px) {
    .food-menu-mobile__times .slick-list {
        /*width: 100vw;*/
        width: 768px
    }
    .superset-mobile__elements .slick-list {
        width: 1100px;
    }
    .health-info-mobile > div {
        display: flex;
        justify-content: space-around;
    }
    .health-info__header {
        width: 500px;
    }
    .phones__opacity {
        display: none;
    }
    .phones__not-about-food {
        /*color: #fff!important;*/
        width: 100%;
    }
    .phones__we-crafted {
        /*color: #fff!important;*/
    }
}

.food-menu-mobile__breakfast,
.food-menu-mobile__dinner,
.food-menu-mobile__lunch,
.food-menu-mobile__superset,
.food-menu-mobile__friday {
    text-align: center;
    background: #fff;
    border-radius: 100px;
    font-size: 15px;
    font-family: "Graphik LCG Light";
    cursor: pointer;
    height: 34px;
    padding-top: 9px;
    width: 64px !important;
    border: 1px solid #ebebeb;
    transition: border .5s, background-color .5s;
    display: flex;
    box-sizing: border-box;
}
.food-menu-mobile__times .slick-slide {
    margin-right: -21px;
}

.food-menu-mobile__times .slick-list {
    margin-right: 0 !important;
}

.food-menu-mobile__breakfast {
    background: #146957;
    border: 1px solid #146957;
    color: #fff;
}

.food-menu-mobile__dishes {
    height: 331px;
    width: 548px;
    position: absolute;
    top: 167px;
    left: 10px;
}
.food-menu-mobile__dishes .slick-slide {
    /*margin-right: 10px !important;*/
}

@media screen and (width: 768px) {
    .food-menu-mobile__dishes .slick-list {
        width: 850px;
    }
}

.health-info-mobile-container {
    height: 1385px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    margin-top: 48px;
}

.health-info-mobile {
    width: 290px;
    height: 1335px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
}

.health-info-mobile img {
    width: 290px;
    height: 292px;
    object-fit: fill;
}

/*.health-info-mobile img:last-child {*/
/*    margin-left: 20px;*/
/*}*/

.health-info-mobile__slider {
    height: 292px;
    width: 290px;
}

.health-info-mobile__dot {
    width: 65px;
    height: 5px;
    background: #EBEBEB;
    box-shadow: 3px 3px 40px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    /*background: #146957;*/
    margin-top: -40px;
}

.slick-active .health-info-mobile__dot {
    background: #146957;
}

.phones-mobile__text {
    width: 290px;
    height: 145px;
    font-size: 14px;
    line-height: 20px;
    font-family: "Graphik LCG Extralight";
    margin-top: 78px;
    width: calc(100vw - 32px);
}

.phones-mobile__text h6 {
    font-size: 21px;
    font-family: "Graphik LCG Extralight";
    font-weight: normal;
    margin-bottom: 0;
    margin-top: 0;
}

.phones__number-one, .phones__number-two, .phones__number-three, .phones__number-four, .phones__number-five {
    position: absolute;
    z-index: 1;
    /*top: 0;*/
    right: 0;
    bottom: -70px;
}
/*.phones__number-one {*/
/*    top: 35%;*/
/*    right: 20px*/
/*}*/
/*.phones__number-two {*/
/*    top: 51.2%;*/
/*}*/
/*.phones__number-three {*/
/*    top: 65.9%;*/
/*}*/
/*.phones__number-four {*/
/*    top: 80.7%;*/
/*}*/
/*.phones__number-five {*/
/*    top: 96.2%;*/
/*}*/

.phones-mobile__text {
    /*top: 10px;*/
    position: absolute;
    top: 480px;
    left: 20px;
    z-index: 1000;
    margin-top: 24px;
}

.phones-mobile__text span {
    position: relative;
    top: 15px;
}

.phones-mobile__text p {
    font-size: 14px;
    font-family: "Graphik LCG Extralight";
    font-weight: normal;
    margin-bottom: 0;
    margin-top: 5px;
}

.our-team-mobile {
    width: calc(100vw + 20px);
    position: relative;
    /*left: -10px;*/
    top: 90px;
}

.our-team-mobile-carousel {
    width: 584px;
    height: 295px;
    /*padding-left: 20px;*/
    margin-top: 121px;
    padding-left: 15px;
}

.our-team-mobile-carousel .slick-track {
    height: 305px !important;
    padding-top: 10px !important;
    top: -10px !important;
}

@media screen and (width: 768px) {
    .our-team-mobile-carousel .slick-list {
        /*width: 1150px;*/
    }
    .our-team-mobile-carousel .slick-arrow {
        display: none;
    }
    .is-not-hype p {
        width: calc(100vw - 48px);
    }
    .our-team-mobile__member {
        width: 240px!important;
    }
    .our-team-mobile__member img {
        width: 240px!important;
    }
}

.our-team-mobile__member {
    width: 272px !important;
    height: 295px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: #fff;
}

.our-team-mobile__member img {
    width: 272px;
    height: 188px;
    /*border-radius-top: 5px;*/
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    object-fit: cover;
}

.our-team-mobile__about-member {
    margin-left: 15px;
    position: relative;
    top: 5px;
}

.our-team-mobile__about-member h6 {
    font-family: 'Graphik LCG Regular';
    font-size: 16px;
    width: 100%;
    margin-bottom: 0;
    margin-top: 17px;
    font-weight: normal;
}

.our-team-mobile__about-member span {
    color: #117592;
    font-size: 16px;
    font-family: 'Graphik LCG Extralight';
    width: 100%;
    position: relative;
    top: 5px;
}

.our-team-mobile__about-member p {
    font-size: 16px;
    font-family: 'Graphik LCG Extralight';
    width: 100%;
    position: relative;
    top: -3px;
}

.our-team-mobile__text {
    margin: 0;
    width: 250px;
    height: 26px;
    font-size: 21px;
    position: absolute;
    top: -40px;
    left: 15px;
    z-index: 1000;
}

.questions-and-answers-mobile__block {
    height: 359px !important;
    background: #EBEBEB;
    border-radius: 5px;
    width: calc(100vw + 20px);
    position: relative;
    left: -10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.questions-and-answers-mobile {
    width: calc(100vw + 20px);
    position: relative;
    left: -10px;
    height: 359px;
    background: #EBEBEB;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    /*margin-bottom: 45px;*/
}

.questions-and-answers-mobile__carousel {
    height: 203px;
    width: 608px;
    /*margin-top: 106px;*/
    /*top: 106px;*/
    /*position: relative;*/
    top: 106px;
    /*margin-left: 290px;*/
    padding-left: 30px;
}

.questions-and-answers-mobile .slick-list {
    width: 555px;
}

.questions-and-answers-mobile__block {
    width: 269px !important;
    height: 203px !important;
    background: #fff;
    margin-left: 15px;
}

.questions-and-answers-mobile__block h6 {
    margin-left: 15px;
    font-family: "Graphik LCG Regular";
    font-size: 14px;
    font-weight: normal;
    margin-top: 29px;
}

.questions-and-answers-mobile__block p {
    font-family: "Graphik LCG Light";
    font-size: 14px;
    margin-left: 15px;
    width: 239px;
    line-height: 20px;
    margin-top: -15px;
}

.questions-and-answers-mobile__carousel .slick-arrow {
    display: none !important;
}

.questions-and-answers-mobile__slider-container {
    width: calc(100vw + 20px);
    position: relative;
    left: -10px;
}

@media screen and (max-width: 414px) {
    .subscribe-form__line-wednesday {
        /*margin-left: 15px;*/
    }
}

@media screen and (min-width: 768px) {
    #app > article.add-email > p > br {display: none}
}

@media screen and (max-width: 767px) {
    .how-it-works__info-schelude {
        margin-top: 0px !important;
        width: calc(100vw - 40px) !important;
        top: 104px;
    }
    .how-it-works__info-superfood {
        width: calc(100vw - 40px) !important;
        top: 104px;
    }
}

/*.food-menu-mobile__times .slick-track {*/
/*    transition: transform .25s;*/
/*}*/
@media screen and (min-width: 555px) and (max-width: 992px) {
    .food-menu-mobile__dishes .slick-list {
        width: 850px;
    }
    .superset .superset-mobile__elements {
        width: 850px!important;
    }
    .superset-mobile__elements .slick-list {
        width: 1200px!important;
    }
    .subscribe-form-container {
        height: 800px!important;
    }
    .subscribe {
        width: 468px!important;
        top: 0;
        height: 650px;
    }
    .subscribe-form {
        width: 468px!important;
    }
    .subscribe-form__subscribe-button {
        width: 430px!important;
    }
    .health-info-mobile > div {
        display: flex;
        justify-content: space-around;
    }
    .health-info-mobile img {
        object-fit: contain;
    }
    .phones__night-image>img {
        height: 600px;
        object-fit: cover;
    }
    .phones__zero-phone {
        width: 300px!important;
    }
    .phones__text-about-app {
        width: 100%!important;
    }
    .phones__opacity {
        display: none;
    }
    .our-team-mobile-carousel {
        width: 1150px!important;
    }
    .is-not-hype p {
        width: 100%!important;
    }
    .spiro-is-quality__text {
        width: 100%!important;
    }
}
